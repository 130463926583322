const language = {
  en: {
    loading: 'Loading ......',
    Name: 'Name',
    UploadTime: 'Upload DateTime',
    User: 'User',
    Users: 'Users',
    Action: 'Action',
    Ticket: 'Ticket',
    NoData: 'No Data',
    Download: 'Download',
    SelecteAll: 'Select All',
    Search: 'Search',
    Home: 'Home',
    MultiDownload: 'Multi Download',
    Upload: 'Upload',
    Username: 'Username',
    Close: 'Close',
    LanguageSetting: 'Language Setting',
    UploadFiles: 'Upload Files',
    cn: 'Chinese',
    en: 'English',
    Open: 'Open',
    Total: 'Total',
    EmptyCurrentPass: 'Please Insert Current Password.',
    EmptyNewPass: 'Please Insert New Password.',
    EmptyNConPass: 'Please Insert Confirm Password.',
    PassNotMatch: 'Warning: Password Not Match',
    WorngCurrentPAss: 'Warning: Current Password Wrong.',
    SameNewandCurrentPass: 'Warning: New Password is same with Current Password.',
    SuccessChangePass: 'Successful Change New Password.',
    ChangePassword: 'Change Password',
    SuccessfulMove: 'Successful Move',
    Setting: 'Setting',
    CurrentPassword: 'Current Password: ',
    NewPass: 'New Password: ',
    ConfirmNewPass: 'Confirm New Password:',
    ActiveDate: 'Active Date',
    BetStatus: 'Bet Date Status',
    BetDateCloseTime: 'Bet Date Close Time',
    SettingDate: 'Setting Date',
    Confirm: 'Confirm',
    UsernameError: 'Error: Username Cannot be Empty',
    AddUser: 'Add User',
    EditUserDetails: 'Edit User Details',
    Password: 'Password',
    Role: 'Role',
    DaAgent: 'DA Agent',
    Agent: 'Agent',
    Player: 'Player',
    Upline: 'Upline',
    ClosingTime: 'Closing Time',
    Update: 'Update',
    SuccessfulUpdate: 'Successfull Update',
    Active: 'Active',
    Delete: 'Delete',
    Move: 'Move',
    MoveTo: 'MoveTo',
    Photo: 'Photo',
    Status: 'Status',
    Readed: 'Readed',
    NotRead: 'Not Read',
    MultiDelete: 'Multi Delete',
    MultiPrint: 'Multi Print',
    MultiMove: 'Multi Move',
    PleaseSelectedData: 'Please Select Data',
    Success: 'Success',
    Date: 'Date',
    Conversation: 'Conversation',
    from: 'From',
    to: 'to',
    Da: 'Da',
    TurnLeft: 'Turn Left',
    TurnRight: 'Turn Right',
    ViewUpload: 'View Upload',
    AbleConvertPDF: 'Able Convert PDF',
  },
  cn: {
    loading: '请稍等, 加载中......',
    Name: '名称',
    UploadTime: '上传时间',
    User: '用户',
    Users: '用户',
    Action: '行动',
    Ticket: '单',
    NoData: '没数据',
    Download: '下载',
    SelecteAll: '全选',
    Search: '寻找',
    Home: '主页',
    MultiDownload: '多重下载',
    Upload: '上传',
    Username: '用户名',
    Close: '关闭',
    LanguageSetting: '语言设定',
    UploadFiles: '上传文件',
    cn: '中文',
    en: '英文',
    Open: '打开',
    Total: '总共',
    EmptyCurrentPass: '请输入当前密码.',
    EmptyNewPass: '请输入新密码.',
    EmptyNConPass: '请输入确认新密码.',
    PassNotMatch: '密码不匹配',
    WorngCurrentPAss: '当前密码错误',
    SameNewandCurrentPass: '新密码与当前密码相同',
    SuccessChangePass: '密码更换成功.',
    ChangePassword: '更换密码',
    SuccessfulMove: '成功移动',
    Setting: '设置',
    CurrentPassword: '当前密码:',
    NewPass: '新密码: ',
    ConfirmNewPass: '确认新密码:',
    ActiveDate: '活跃日期',
    BetStatus: '字期情况',
    BetDateCloseTime: '字期关闭时间',
    SettingDate: '设置日期',
    Confirm: '确定',
    UsernameError: '错误: 用户名不能放空',
    AddUser: '增加用户',
    EditUserDetails: '更换用户资料',
    Password: '密码',
    Role: '角色',
    DaAgent: '大代理',
    Agent: '代理',
    Player: 'Player',
    Upline: '上线',
    ClosingTime: '关闭时间',
    Update: '更新',
    SuccessfulUpdate: '成功更新',
    Active: '活跃',
    Delete: '删除',
    Move: '移动',
    MoveTo: '移动至',
    Photo: '照片',
    Status: '状态',
    Readed: '已读',
    NotRead: '未读',
    MultiDelete: '多重删除',
    MultiPrint: '多重打印',
    MultiMove: '多重移动',
    PleaseSelectedData: '请选择文件',
    Success: '成功',
    Date: '日期',
    Conversation: '对话',
    from: '从',
    to: '到',
    Da: '大代理',
    TurnLeft: '转左',
    TurnRight: '转右',
    ViewUpload: '查看上传',
    AbleConvertPDF: '可以转换PDF',
  },
}
    
exports.language = language
    