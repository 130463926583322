import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import { Form, Button, Table, Modal, Alert } from 'react-bootstrap';
import { getSearchUserList, createUser, updateUser, deleteUser, getAllUserList } from '../Api';
import Header from '../Component/Header';
import moment from 'moment-timezone';


var timer

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      userObj: [],
      filterUser: '',
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      pdfData: [],
      addUserModalShow: false,
      activeUpdateID: '',

      createUserUsername: '',
      createUserName: '',
      createUserPassword: '',
      createUserActive: true,
      createUserAbleConvertPDF: false,
      createUserRole: 'Da',
      createUserUpline: '',

      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      UpdateDetails: false,

      SearchbyName: '',
      SearchbyRole: '',
      SearchbyUsername: '',

      OpenDeleteModal: false,
      ConfirmPassword: '',
      DetailsID: '',
      createUserDownline: [],
      selfUser: [],
      currentUser: '',
      createUserTime: moment("19:00", "HH:mm").format("HH:mm"),
      allUser: [],
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleChangeDownline = this.handleChangeDownline.bind(this)
  }

  async componentDidMount() {
    if (this.props.username === '') {
      this.props.history.push('/')
    } else {
      this.getAllUserListFunction();
      this.GetSearchUserList('', '', '', '');
    }
  }

  async getAllUserListFunction() {
    const allUser = await getAllUserList(this.props.username, this.props.token)
    this.setState({ allUser })
  }
  async GetSearchUserList(filterUsername, filterName, filterRole, upline) {
    let userList = await getSearchUserList(this.props.username, this.props.token, filterUsername, filterName, filterRole, upline)
    if (this.state.currentUser === '') {
      this.setState({ userList });
    } else {
      const selfUser = userList.find((item) => item.ID === this.state.currentUser)
      userList = userList.filter((item) => item.ID !== this.state.currentUser)
      this.setState({ selfUser: [selfUser], userList });
    }
    
  }

  handleClose() {
    this.setState({ 
      ConfirmPassword: '',
      OpenDeleteModal: false,
      addUserModalShow: false,
      UpdateDetails: false,
      createUserUsername: '',
      createUserName: '',
      createUserActive: true,
      createUserAbleConvertPDF: false,
      createUserPassword: '',
      createUserRole: 'Da',
      createUserUpline: '',
      DetailsID: '',
    })
  }

  async createUserF() {
    const createUserObject = {
      Username: this.state.createUserUsername,
      Name: this.state.createUserName,
      Password: this.state.createUserPassword,
      Active: (this.state.createUserActive) ? 1 : 0,
      AbleConvertPDF: (this.state.createUserAbleConvertPDF) ? 1 : 0,
      Role: this.state.createUserRole,
      Downline: this.state.createUserDownline,
      Upline: this.state.createUserUpline,
      ClosingTime: this.state.createUserTime,
    }
    
    if (this.state.createUserUsername === '') {
      this.openNotification('Username不能放空', 'error')
    } else if (this.state.createUserUpline === '') {
      this.openNotification('请选择上线', 'error')
    } else if (this.state.createUserPassword === '') {
      this.openNotification('密码不能放空', 'error')
    } else {
      const createUserResult = await createUser(this.props.username, this.props.token, createUserObject)
      if (createUserResult.message && createUserResult.message === 'User Created Successful') {
        this.openNotification('增加成功', '')
        this.handleClose()
        this.getAllUserListFunction();
        this.GetSearchUserList(this.state.SearchbyUsername, this.state.SearchbyName, this.state.SearchbyRole, this.state.currentUser);
        this.setState({ UpdateDetails: false });
      }
      if (createUserResult.error === 'Duplicated Username, Please Choose Another'){
        this.openNotification('用户名重复，请另选', '')
      }
    }
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({
        showNotification: false,
        notificationMessage: ''
      });
    }, 5000);
  };

  async DeleteUsers(details) {
    this.setState({OpenDeleteModal: true, DetailsID: details.ID})
  }

  async ConfirmDelete() {
    const ConfirmPassword = this.state.ConfirmPassword
    if(ConfirmPassword === '') {
      this.openNotification('Wrong Password','error')
    } else {
      const deleteUserResult = await deleteUser(this.props.username, this.state.DetailsID, ConfirmPassword, this.props.token)
      if (deleteUserResult.message === 'Wrong Password') {
        this.openNotification('删除失败, 密码错误', 'error')
      } else {
        if (deleteUserResult.message === 'User Deleted Successful') {
          this.openNotification('删除成功', '')
          this.handleClose()
          this.getAllUserListFunction();
          this.GetSearchUserList((this.state.SearchbyUsername, this.state.SearchbyName, this.state.SearchbyRole, this.state.currentUser));
        } else {
          this.openNotification('删除失败', 'error')
        }
      }
    }
  }

  ChangeUserDetails(details) {
    this.setState({
      activeUpdateID: details.ID,
      createUserUsername: details.Username, 
      createUserName: details.Name, 
      createUserPassword: details.Password, 
      createUserActive: details.Active,
      createUserRole: details.Role,
      createUserUpline: details.Upline || '',
      createUserDownline: JSON.parse(details.Downline),
      createUserTime: details.ClosingTime, 
      addUserModalShow: true, 
      UpdateDetails: true, 
    })
  }

  async UpdateUsersDetails() {
    const Passobject = {
      ID: this.state.activeUpdateID,
      Username: this.state.createUserUsername,
      Name: this.state.createUserName,
      Password: this.state.createUserPassword,
      Active: (this.state.createUserActive) ? 1 : 0,
      AbleConvertPDF: (this.state.createUserAbleConvertPDF) ? 1 : 0,
      Role: this.state.createUserRole,
      Downline: this.state.createUserDownline,
      Upline: this.state.createUserUpline,
      ClosingTime: this.state.createUserTime,
    }
    if(this.state.createUserUsername === '') {
      this.openNotification(`${language[this.props.currentLanguage].UsernameError}`, 'error')
    } else if (this.state.createUserRole === '') {
      this.openNotification('空角色', 'error')
    } else if (this.state.createUserPassword === '') {
      this.openNotification('密码不能放空', 'error')
    } else {
      const updateUserResult = await updateUser(this.props.username, this.props.token, Passobject)
      if (updateUserResult.message && updateUserResult.message === 'User Updated Successful') {
        this.openNotification('更新成功', '')
        this.handleClose()
        this.getAllUserListFunction();
        this.GetSearchUserList(this.state.SearchbyUsername, this.state.SearchbyName, this.state.SearchbyRole, this.state.currentUser);
      } else if(updateUserResult.error === 'Time Out Upload'){
        this.openNotification(`${updateUserResult.error}`, 'error')
      } else {
        this.openNotification('更新失败', 'error')
      }
    }
  }

  translateRole(value) {
    if (value === 'Admin') {
      return 'Admin'
    } else if (value === 'Neiwu') {
      return '内务'
    } else if (value === 'Bumen') {
      return '部门'
    } else if (value === 'Zu') {
      return '组'
    } else if (value === 'Da') {
      return '大代理'
    } else if (value === 'Agent') {
      return '代理'
    } else if (value === 'Player') {
      return '玩家'
    } 
  }

  ChangeRole(value) {
    this.setState({
      createUserRole: value, 
      createUserUpline: '',
    })
  }

  handleChangeDownline(id) {
    let copyArray = JSON.parse(JSON.stringify(this.state.createUserDownline))
    if (this.state.createUserDownline.includes(id)) {
      copyArray = copyArray.filter((item) => item !== id)
    } else {
      copyArray.push(id)
    }
    this.setState({ createUserDownline: copyArray })
  }

  selectDownline(id, upline) {
    if (upline) {
      if (this.state.selfUser.length >= 1 && this.state.selfUser[0].Role === 'Da') {
        this.setState({ currentUser: '', selfUser: [] }, () => {
          this.GetSearchUserList('', '', '', '')
        })
      } else {
        this.setState({ currentUser: this.state.selfUser[0].Upline }, () => {
          this.GetSearchUserList('', '', '', this.state.selfUser[0].Upline)
        })
      }
    } else {
      this.setState({ currentUser: id }, () => {
        this.GetSearchUserList('', '', '', id)
      })
    }
    this.setState({ SearchbyUsername: '', SearchbyName: '', SearchbyRole: ''})
  }

  SearchUser(e) {
    if (e.key === 'Enter') {
      this.GetSearchUserList(this.state.SearchbyUsername, this.state.SearchbyName, this.state.SearchbyRole, '')
    }
  }

  enterUpdateUsersDetails(e) {
    if (e.key === 'Enter') {
      this.UpdateUsersDetails()
    }
  }

  openCreateModal() {
    if (this.state.selfUser.length >= 1) {
      if (this.state.selfUser[0].Role === 'Da') {
        this.setState({ createUserRole: 'Agent' })
      } else if (this.state.selfUser[0].Role === 'Agent'){
        this.setState({ createUserRole: 'Player' })
      }
      this.setState({ createUserUpline: this.state.selfUser[0].ID, addUserModalShow: true, createUserTime: moment("19:00", "HH:mm").format("HH:mm") })
    } else {
      this.setState({ addUserModalShow: true, createUserTime: moment("19:00", "HH:mm").format("HH:mm") })
    }
    
  }
  render() {
    return (
      <div fluid style={{ fontFamily: 'cursive', backgroundImage: 'linear-gradient(#D0F0F5, #E7EDFF, #F0E8FF)', minHeight: '100vh' }}>
        <Header style={{ position: 'absolute', top: 0}}/>
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <Modal centered show={this.state.addUserModalShow} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {(this.state.UpdateDetails === true) ? `${language[this.props.currentLanguage].EditUserDetails}` :`${language[this.props.currentLanguage].AddUser}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>{language[this.props.currentLanguage].Username}</div>
              <Form.Control onKeyDown={(e) => this.enterUpdateUsersDetails(e)} style={{ width: 150}} value={this.state.createUserUsername} onChange={(e) => this.setState({ createUserUsername: e.target.value })} />
            </div>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>{language[this.props.currentLanguage].Name}</div>
              <Form.Control onKeyDown={(e) => this.enterUpdateUsersDetails(e)} style={{ width: 150}} value={this.state.createUserName} onChange={(e) => this.setState({ createUserName: e.target.value })} />
            </div>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>{language[this.props.currentLanguage].Password}</div>
              <Form.Control onKeyDown={(e) => this.enterUpdateUsersDetails(e)} style={{ width: 150}} value={this.state.createUserPassword} onChange={(e) => this.setState({ createUserPassword: e.target.value })} />
            </div>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>{language[this.props.currentLanguage].Role}</div>
              <Form.Select disabled={this.state.UpdateDetails}  style={{ width: 150 }} value={this.state.createUserRole} onChange={(e) => this.ChangeRole(e.target.value)}>
                <option value={'Da'}>大代理</option>
                <option value={'Agent'}>{language[this.props.currentLanguage].Agent}</option>
                <option value={'Player'}>{language[this.props.currentLanguage].Player}</option>
              </Form.Select>
            </div>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>{language[this.props.currentLanguage].Upline}</div>
              <Form.Select style={{ width: 150}} value={this.state.createUserUpline} onChange={(e) => this.setState({ createUserUpline: e.target.value })}>
                <option></option>
                {(this.state.createUserRole === 'Da') ? 
                  this.state.allUser.map((item, index) => 
                    (item.Role === 'Zu')&&<option key={index} value={item.ID}>{item.Username}</option>)
                  : (this.state.createUserRole === 'Agent') ?
                    this.state.allUser.map((item, index) => 
                      (item.Role === 'Da')&&<option key={index} value={item.ID}>{item.Username}</option>)
                    : (this.state.createUserRole === 'Player') ?
                      this.state.allUser.map((item, index) => 
                        (item.Role === 'Agent')&&<option key={index} value={item.ID}>{item.Username}</option>)
                      : <option></option>
                }
              </Form.Select>
            </div>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>{language[this.props.currentLanguage].ClosingTime}</div>
              <Form.Control value={this.state.createUserTime} onChange={(e) => this.setState({ createUserTime: e.target.value})} type="time" style={{ marginRight: 10, width: 180 }}/>
            </div>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>{language[this.props.currentLanguage].Active}</div>
              <Form.Check type="checkbox" checked={this.state.createUserActive} onChange={(e) => this.setState({ createUserActive: e.target.checked})}/>
            </div>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>{language[this.props.currentLanguage].AbleConvertPDF}</div>
              <Form.Check type="checkbox" checked={this.state.createUserAbleConvertPDF} onChange={(e) => this.setState({ createUserAbleConvertPDF: e.target.checked})}/>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => this.handleClose()}>{language[this.props.currentLanguage].Close}</Button>
            {this.state.UpdateDetails === true ?
              <Button variant="success" onClick={() => this.UpdateUsersDetails()}>提交</Button>
              : 
              <Button variant="success" onClick={() => this.createUserF()}>提交</Button>
            }
          </Modal.Footer>
        </Modal>
        <Modal centered show={this.state.OpenDeleteModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirm Delete
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>Username</div>
              <Form.Control style={{ width: 150}} value={this.props.username} disabled={true}/>
            </div>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>确认密码</div>
              <Form.Control style={{ width: 150}} value={this.state.ConfirmPassword} onChange={(e) => this.setState({ ConfirmPassword: e.target.value })} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => this.handleClose()}>关闭</Button>
            <Button variant="danger" onClick={() => this.ConfirmDelete()}>确定删除</Button>
          </Modal.Footer>
        </Modal>
        <div className='UserListView'>
          <div className='UserListInsideView'>
            <h2 className='TitleSize'>用户名单</h2>
            <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Username}</div>
            <Form.Control className='UsrListInput' value={this.state.SearchbyUsername} onKeyDown={(e) => this.SearchUser(e)} onChange={(e) => this.setState({SearchbyUsername: e.target.value})}>

            </Form.Control>
            <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Name}</div>
            <Form.Control className='UsrListInput' value={this.state.SearchbyName} onKeyDown={(e) => this.SearchUser(e)} onChange={(e) => this.setState({SearchbyName: e.target.value})}>
            </Form.Control>
            <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Role}</div>
            <Form.Select className='UsrListInput' value={this.state.SearchbyRole} onChange={(e) => this.setState({ SearchbyRole: e.target.value }, () => this.SearchUser({ key: 'Enter'}))}>
              <option></option>
              <option value={'Da'}>{language[this.props.currentLanguage].Da}</option>
              <option value={'Agent'}>{language[this.props.currentLanguage].Agent}</option>
              <option value={'Player'}>{language[this.props.currentLanguage].Player}</option>
            </Form.Select>
            <div className='UserListButton'>
              <button className="button2" onClick={() => this.SearchUser({ key: 'Enter'})}>{language[this.props.currentLanguage].Search}</button>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className='UserListButton'>
              {this.state.selfUser.length >= 1 && <button className="buttonAdd" style={{ marginRight: 5, backgroundColor: '#0d6efd' }} onClick={() => this.selectDownline('', true)}>返回上线</button>}
              <button className="buttonAdd" onClick={() => this.openCreateModal()}>增加</button>
            </div>
          </div>
        </div>
        <div className="tableFixed">
          <Table striped bordered hover responsive style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }} className='userList'>
            <thead className='userList'>
              <tr>
                <th style={{ minWidth: '100px' }}>Username</th>
                <th style={{ minWidth: '100px' }}>名字</th>
                <th style={{ minWidth: '100px' }}>活跃</th>
                <th style={{ minWidth: '100px' }}>角色</th>
                <th style={{ minWidth: '100px' }}>行动</th>
              </tr>
            </thead>
            <tbody className='userList'>
              {this.state.selfUser.length >= 1 && this.state.selfUser.map((item, index) => 
                <tr key={index}>
                  <td>{item.Username} ({item.downlineCount})</td>
                  <td>{item.Name}</td>
                  <td>{item.Active === 1 ? '✔️' : '❌'}</td>
                  <td>{this.translateRole(item.Role)}</td>
                  <td style={{ display: 'flex' }}>
                    <div style={{ marginRight: 5 }}>
                      <button className="button2" onClick={() => this.ChangeUserDetails(item)}>换资料</button>
                    </div>
                    <div style={{ marginRight: 5 }}>
                      <button className="buttonDelete" onClick={() => this.DeleteUsers(item)}>删除户口</button>
                    </div>
                  </td>
                
                </tr>)}
              {this.state.selfUser.length >= 1 && <tr><td colSpan={5} style={{backgroundColor: '#000', color: '#fff', textAlign: 'center'}}>下线</td></tr>}
              {this.state.userList.map((item, index) => 
                (item.Role !== 'Zu') && <tr key={index}>
                  <td><Button className="button2" onClick={() => this.selectDownline(item.ID)}>{item.Username} ({item.downlineCount})</Button></td>
                  <td>{item.Name}</td>
                  <td>{item.Active === 1 ? '✔️' : '❌'}</td>
                  <td>{this.translateRole(item.Role)}</td>
                  <td style={{ display: 'flex' }}>
                    <div style={{ marginRight: 5 }}>
                      <button className="button2" onClick={() => this.ChangeUserDetails(item)}>换资料</button>
                    </div>
                    <div style={{ marginRight: 5 }}>
                      <button className="buttonDelete" onClick={() => this.DeleteUsers(item)}>删除户口</button>
                    </div>
                  </td>
                </tr>)}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
