import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Login from './Page/Login';
import ScanList from './Page/ScanList';
import UserList from './Page/UserList';
import AdminList from './Page/AdminList';
import UserHomePage from './Page/UserHomePage';
import Configuration from './Page/Configuration';
import ChangePassword from './Page/ChangePassword';
import OpenWindowReceipt from './Page/OpenWindowReceipt';
import OpenSelectedImg from './Component/OpenSelectedImg';
import HomepageUSers from './Page/HomepageUSers';
import Chating from './Page/Chating';
import Work from './Page/Work';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Header from './Component/Header';
import { connect } from 'react-redux';

class AppFunction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return(
      <Router style={{ position: 'relative' }}>
        <div className="App">
          {/* <Header/> */}
          {
            this.props.role === 'Admin' && <Switch>
              <Route exact path="/" component={( this.props.role === 'Admin') ? Work : Login}/>
              <Route exact path="/ScanList" component={ScanList} />
              <Route exact path="/UserList" component={UserList} />
              <Route exact path="/AdminList" component={AdminList} />
              <Route exact path="/UserHomePage" component={UserHomePage} />
              <Route exact path="/Work" component={Work} />
              <Route exact path="/OpenWindowReceipt" component={OpenWindowReceipt} />
              <Route exact path="/OpenSelectedImg" component={OpenSelectedImg} />
              <Route exact path="/Configuration" component={Configuration} />
              <Route exact path="/ChangePassword" component={ChangePassword} />
              <Route exact path="/Chating" component={Chating} />
            </Switch>
          }
          {
            this.props.role === 'Neiwu' && <Switch>
              <Route exact path="/" component={( this.props.role === 'Neiwu') ? Work : Login}/>
              <Route exact path="/ScanList" component={ScanList} />
              <Route exact path="/UserHomePage" component={UserHomePage} />
              <Route exact path="/Work" component={Work} />
              <Route exact path="/OpenWindowReceipt" component={OpenWindowReceipt} />
              <Route exact path="/OpenSelectedImg" component={OpenSelectedImg} />
              <Route exact path="/Configuration" component={Configuration} />
              <Route exact path="/ChangePassword" component={ChangePassword} />
              <Route exact path="/Chating" component={Chating} />
            </Switch>
          }
          {
            this.props.role === 'Bumen' && <Switch>
              <Route exact path="/" component={( this.props.role === 'Bumen') ? Work : Login}/>
              <Route exact path="/ScanList" component={ScanList} />
              <Route exact path="/UserHomePage" component={UserHomePage} />
              <Route exact path="/Configuration" component={Configuration} />
              <Route exact path="/Work" component={Work} />
              <Route exact path="/OpenWindowReceipt" component={OpenWindowReceipt} />
              <Route exact path="/OpenSelectedImg" component={OpenSelectedImg} />
              <Route exact path="/ChangePassword" component={ChangePassword} />
              <Route exact path="/Chating" component={Chating} />
            </Switch>
          }
          {
            this.props.role === 'Zu' && <Switch>
              <Route exact path="/" component={( this.props.role === 'Zu') ? Work : Login}/>
              <Route exact path="/ScanList" component={ScanList} />
              <Route exact path="/UserHomePage" component={UserHomePage} />
              <Route exact path="/Configuration" component={Configuration} />
              <Route exact path="/Work" component={Work} />
              <Route exact path="/OpenWindowReceipt" component={OpenWindowReceipt} />
              <Route exact path="/OpenSelectedImg" component={OpenSelectedImg} />
              <Route exact path="/ChangePassword" component={ChangePassword} />
              <Route exact path="/Chating" component={Chating} />
            </Switch>
          }
          {
            this.props.role === 'Da' && <Switch>
              <Route exact path="/" component={( this.props.role === 'Da') ? ScanList : Login}/>
              <Route exact path="/ScanList" component={ScanList} />
              <Route exact path="/UserHomePage" component={UserHomePage} />
              <Route exact path="/Configuration" component={Configuration} />
              <Route exact path="/Work" component={Work} />
              <Route exact path="/OpenWindowReceipt" component={OpenWindowReceipt} />
              <Route exact path="/OpenSelectedImg" component={OpenSelectedImg} />
              <Route exact path="/HomepageUSers" component={HomepageUSers} />
              <Route exact path="/ChangePassword" component={ChangePassword} />
              <Route exact path="/Chating" component={Chating} />
            </Switch>
          }
          {
            this.props.role === 'Agent' && <Switch>
              <Route exact path="/" component={( this.props.role === 'Agent') ? ScanList : Login}/>
              <Route exact path="/ScanList" component={ScanList} />
              <Route exact path="/UserHomePage" component={UserHomePage} />
              <Route exact path="/Configuration" component={Configuration} />
              <Route exact path="/Work" component={Work} />
              <Route exact path="/OpenWindowReceipt" component={OpenWindowReceipt} />
              <Route exact path="/OpenSelectedImg" component={OpenSelectedImg} />
              <Route exact path="/HomepageUSers" component={HomepageUSers} />
              <Route exact path="/ChangePassword" component={ChangePassword} />
              <Route exact path="/Chating" component={Chating} />
            </Switch>
          }
          {
            this.props.role === 'Player' && <Switch>
              <Route exact path="/" component={( this.props.role === 'Player') ? ScanList : Login}/>
              <Route exact path="/ScanList" component={ScanList} />
              <Route exact path="/UserHomePage" component={UserHomePage} />
              <Route exact path="/Configuration" component={Configuration} />
              {/* <Route exact path="/Work" component={Work} /> */}
              <Route exact path="/OpenWindowReceipt" component={OpenWindowReceipt} />
              <Route exact path="/OpenSelectedImg" component={OpenSelectedImg} />
              <Route exact path="/HomepageUSers" component={HomepageUSers} />
              <Route exact path="/ChangePassword" component={ChangePassword} />
              <Route exact path="/Chating" component={Chating} />
            </Switch>
          }
          {
            this.props.role === '' && <Switch>
              <Route exact path="/" component={Login} />
            </Switch>
          }
          <Route exact path="/OpenWindowReceipt" component={OpenWindowReceipt} />
          <Route exact path="/OpenSelectedImg" component={OpenSelectedImg} />
        </div>
      </Router>
    )
  };
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    date: state.date,
  };
}

export default connect(mapStateToProps, null)(AppFunction);
