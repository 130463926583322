import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { getOpenDatedFNC, getServerTimeFNC } from '../Api'
import { language } from '../language';
import { TimeZone } from '../Component/TimeZoneKL';
import { isMobile } from 'react-device-detect';
import { Container, Button } from 'react-bootstrap';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DisabledDate: [new Date()],
      SelectedDate: this.props.date,
      enabledDare: [],
      Serverdate: '',
    }
  }

  async componentDidMount() {
    this.CallUsableDate()
    const CallDateTime = await getServerTimeFNC(this.props.username, this.props.token)
    this.setState({
      // Serverdate: moment(CallDateTime).format('YYYY-MM-DD HH:mm:ss')
      Serverdate: moment(CallDateTime).format('hh:mm:ss a YYYY-MM-DD')
    });
    this.timerID = setInterval(
      () => this.runtime(),
      1000
    );
  }

  async runtime() {
    if (this.state.Serverdate !== '') {
      this.setState({
        // Serverdate: moment(this.state.Serverdate, 'YYYY-MM-DD HH:mm:ss').add(1, 's').format('YYYY-MM-DD HH:mm:ss')
        Serverdate: moment(this.state.Serverdate, 'hh:mm:ss a YYYY-MM-DD').add(1, 's').format('hh:mm:ss a YYYY-MM-DD')
      });
    }
  }

  async CallUsableDate() {
    const GetDates = await getOpenDatedFNC(this.props.username, this.props.token)
    let EnalbedDate = []
    let Dates = GetDates
    for(var i=0; i < Dates.length; i++) {
      EnalbedDate.push(new Date(Dates[i]))
    }
    this.setState({ enabledDare: EnalbedDate })
  }

  changeDate(value) {
    sessionStorage.setItem("scandate", value);
    this.setState({ SelectedDate: value })
    this.props.setDate(value)
    window.location.reload(false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.username !== prevProps.username) {
      this.CallUsableDate();
    }
  }

  render() {
    return (
      <div style={{ fontFamily: 'cursive', width: '100%'}}>
        {this.props.username && <Navbar sticky="top" expand="lg" bg="dark" variant="dark">
          <Container fluid>
            {this.props.role === 'Agent' || this.props.role === 'Player' || this.props.role === 'Da'?
              <Navbar.Brand href="/HomepageUSers">{language[this.props.currentLanguage].Home}</Navbar.Brand>
              : <Navbar.Brand href="/Work">{language[this.props.currentLanguage].Home}</Navbar.Brand>}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                {this.props.role === 'Admin' && 
                <>
                  <Nav.Link href="/ScanList">游览</Nav.Link>
                  <Nav.Link href="/UserList">{language[this.props.currentLanguage].Users}</Nav.Link>
                  <Nav.Link href="/AdminList">工作人员</Nav.Link>
                  <Nav.Link href="/ChangePassword">{language[this.props.currentLanguage].ChangePassword}</Nav.Link>
                  <Nav.Link href="/Chating">{language[this.props.currentLanguage].Conversation}</Nav.Link>
                  <Nav.Link href="/Configuration">{language[this.props.currentLanguage].Setting}</Nav.Link>
                </>
                }
                {this.props.role === 'Neiwu' && 
                <>
                  <Nav.Link href="/ScanList">游览</Nav.Link>
                  {/* <Nav.Link href="/UserHomePage">上传</Nav.Link> */}
                  <Nav.Link href="/Chating">{language[this.props.currentLanguage].Conversation}</Nav.Link>
                  <Nav.Link href="/ChangePassword">{language[this.props.currentLanguage].ChangePassword}</Nav.Link>
                  <Nav.Link href="/Configuration">{language[this.props.currentLanguage].Setting}</Nav.Link>
                </>
                }
                {this.props.role === 'Bumen' && 
                <>
                  <Nav.Link href="/ScanList">游览</Nav.Link>
                  {/* <Nav.Link href="/UserHomePage">上传</Nav.Link> */}
                  <Nav.Link href="/Chating">{language[this.props.currentLanguage].Conversation}</Nav.Link>
                  <Nav.Link href="/ChangePassword">{language[this.props.currentLanguage].ChangePassword}</Nav.Link>
                  <Nav.Link href="/Configuration">{language[this.props.currentLanguage].Setting}</Nav.Link>
                </>
                }
                {this.props.role === 'Zu' && 
                <>
                  <Nav.Link href="/ScanList">游览</Nav.Link>
                  {/* <Nav.Link href="/UserHomePage">上传</Nav.Link> */}
                  <Nav.Link href="/Chating">{language[this.props.currentLanguage].Conversation}</Nav.Link>
                  <Nav.Link href="/ChangePassword">{language[this.props.currentLanguage].ChangePassword}</Nav.Link>
                  <Nav.Link href="/Configuration">{language[this.props.currentLanguage].Setting}</Nav.Link>
                </>
                }
                {this.props.role === 'Da' && 
                <>
                  <Nav.Link href="/ScanList">游览</Nav.Link>
                  {/* <Nav.Link href="/UserHomePage">上传</Nav.Link> */}
                  <Nav.Link href="/Chating">{language[this.props.currentLanguage].Conversation}</Nav.Link>
                  <Nav.Link href="/ChangePassword">{language[this.props.currentLanguage].ChangePassword}</Nav.Link>
                  <Nav.Link href="/Configuration">{language[this.props.currentLanguage].Setting}</Nav.Link>
                </>
                }
                {this.props.role === 'Agent' && 
                <>
                  <Nav.Link href="/ScanList">游览</Nav.Link>
                  {/* <Nav.Link href="/UserHomePage">上传</Nav.Link> */}
                  <Nav.Link href="/Chating">{language[this.props.currentLanguage].Conversation}</Nav.Link>
                  <Nav.Link href="/ChangePassword">{language[this.props.currentLanguage].ChangePassword}</Nav.Link>
                  <Nav.Link href="/Configuration">{language[this.props.currentLanguage].Setting}</Nav.Link>
                </>
                }
                {this.props.role === 'Player' && 
                <>
                  {/* <Nav.Link href="/UserHomePage">上传</Nav.Link> */}
                  <Nav.Link href="/ScanList">游览</Nav.Link>
                  <Nav.Link href="/Chating">{language[this.props.currentLanguage].Conversation}</Nav.Link>
                  <Nav.Link href="/ChangePassword">{language[this.props.currentLanguage].ChangePassword}</Nav.Link>
                  <Nav.Link href="/Configuration">{language[this.props.currentLanguage].Setting}</Nav.Link>
                </>
                }
              </Nav>
              <Nav>
                <div className='dcc' style={{ color: 'white', marginRight: 5 }}>{this.state.Serverdate}</div>
                <div className='dcc'>
                  <DatePicker
                    className={"form-control StyleDatePickerMobile"}
                    inputClass="custom-input"
                    onChange={(e) => this.changeDate(e)}
                    placeholder="Date"
                    selected={new Date(this.props.date)}
                    includeDates={this.state.enabledDare}
                    value={moment(this.props.date).format('YYYY/MM/DD')}
                    withPortal
                  />
                </div>
                <Nav.Link href="#" disabled style={{color: 'white'}}>登入：{this.props.username}</Nav.Link>
                <Button variant="danger" onClick={() => {
                  sessionStorage.removeItem('scantoken');
                  sessionStorage.removeItem('scanusername');
                  sessionStorage.removeItem('scanrole');
                  sessionStorage.removeItem('UserSelectedList');
                  window.location.href = '/';
                }}>登出</Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>}
        <script type="text/javascript">
          var
        </script>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    date: state.date,
    currentLanguage: state.currentLanguage,
    userID: state.userID,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setDate: (date) => {
      dispatch({
        type: 'SET_DATE',
        payload: {
          date,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Header);
