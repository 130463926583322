import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { ListGroup, Form, Button, Alert } from 'react-bootstrap';
import io from 'socket.io-client';
import { getBase64 } from '../Component/getBase64';
import Header from '../Component/Header';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import { getUserList, createUser, updateUser, getDownlineChatlist, getChatHistoryf, SendMessage, socketbase, getUnreadMsg, getBase64API } from '../Api';
import ReactAudioPlayer from 'react-audio-player';
import moment from 'moment-timezone';
import { isMobile } from 'react-device-detect';
import MicRecorder from 'mic-recorder-to-mp3';

var timer

const recorders = new MicRecorder({
  bitRate: 128
})

const utilizeScroll = () => {
  
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end"});

  return { executeScroll, elRef };
};

class Chating extends React.Component {

  fileObj = [];
  fileArray = [];
  Base64 = []

  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      pdfData: [],
      addUserModalShow: false,
      activeUpdateID: '',
      createUserUsername: '',
      createUserName: '',
      createUserPassword: '',
      createUserSpace: 1000,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      UpdateDetails: false,
      SearchbyName: '',
      SearchbyID: '',
      SearchbyUsername: '',
      selecteduserindex: '',
      message: '',
      getMessageList: [],
      ListUser: [],
      TextRow: 1,
      lineData: [{
        Word: '',
      },],
      ChatArrayHistory: [],
      SearchUser: '',
      CountMessage: 20,
      DisplayUser: '',
      ModalShow: false,
      ImageDisplay: '',
      ChatUserID: '',
      ViewPrevious: false,
      display: true,
      userChat: '',
      userChatname: '',
      FilterListUser: [],
      filterUserRole: '',
      SelectedImage: '',
      SelectedImageModal: false,
      file: [],
      Base64file: [],
      BooleanSendImage: false,
      UserOnBehalf: '',
      UploadModal: false,
      ShowUSersList: true,
      browserType: 'chrome',
      isRecording: false,
      Recoreddd: false,
      recordings: '',
      AudioArray: '',
      Base64Mp3: '',
    }
    this.socket = io(socketbase);
    this.FocusHere = utilizeScroll();
    this.handleClose = this.handleClose.bind(this)
    this.keydownHandler = this.keydownHandler.bind(this)
    this.SetMessage = this.SetMessage.bind(this)
    this.MessageToUserFunction = this.MessageToUserFunction.bind(this)
    this.LatestMessageReceive = this.LatestMessageReceive.bind(this)
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.fileUpload = React.createRef();
    this.showFileUpload = this.showFileUpload.bind(this);
    this.handleCloseImage = this.handleCloseImage.bind(this)
    this.DownloadPDFFile = this.DownloadPDFFile.bind(this)
  }

  async componentDidMount() {
    if (navigator.userAgent.indexOf("Firefox") > 0) {
      this.setState({ browserType: 'firefox' })
    }
    this.socket.on('connect', () => {
      this.HandleSocket();
    });

    let ListUserResult = await getDownlineChatlist(this.props.username, this.props.token, moment(this.props.date).format('YYYY-MM-DD'));
    // console.log('ListUserResult', ListUserResult)
    if (ListUserResult.length >= 1 && ListUserResult.findIndex((item) => item.Username === 'Company')) {
      ListUserResult = this.swapItems(ListUserResult, 0, ListUserResult.findIndex((item) => item.Username === 'Company'))
    }
    const removeCompany = ListUserResult.filter(item => item.Username !== 'Company' );

    this.setState({ ListUser: ListUserResult, FilterListUser: ListUserResult, ListAllowUserUpload: removeCompany, selecteduserindex: ''})
    this.getUnreadMessage();
  }

  async getUnreadMessage() {
    const getUnread = await getUnreadMsg(this.props.username, this.props.userID, this.props.token)
    console.log('getUnread', getUnread)
  }


  async componentWillUnmount() {
    this.socket && this.socket.disconnect();
  }

  handleClose() {
    this.setState({
      addUserModalShow: false,
      UpdateDetails: false,
      createUserUsername: '',
      createUserName: '',
      createUserPassword: '',
      createUserSpace: 1000,
    })
  }

  async SearchUser() {
    const searchObj = {
      SearchbyID: this.state.SearchbyID,
      SearchbyUsername: this.state.SearchbyUsername,
      SearchbyName: this.state.SearchbyName,
    }
    const userList = await getUserList(this.props.id, this.props.username, this.props.token, 'details', searchObj)
    this.setState({ userList });
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 5000);
  };

  async keydownHandler(e, numb){
    let AddNewline = JSON.parse(JSON.stringify(this.state.lineData))
    if(e.keyCode===13 && e.ctrlKey) {
      const AddNewline = JSON.parse(JSON.stringify(this.state.lineData))
      AddNewline.push({
        Word: '',
      })
      this.setState({lineData:AddNewline}, ()=>{
        const Next = AddNewline.length - 1;
        this['Input'+ Next].focus();
      })
    } else if(e.keyCode===13 ){
      this.SendMessage();
    }
    if(e.key === 'Backspace' && AddNewline.length > 1 && (AddNewline[numb].Word).length === 0){
      const NewArray = []
      AddNewline.forEach((item, index) =>{
        if(numb !== index){
          NewArray.push(item)
        }
      });
      this.setState({lineData: NewArray}, ()=>{
        const Next = (numb !== 0) ? numb - 1 : numb
        this['Input'+ Next].focus();
      })
    }
    if(e.key === 'ArrowUp'){
      const Next = numb - 1
      if ( Next !== -1 ){
        this['Input'+ Next].focus();
        e.preventDefault();
      }
    }
    if(e.key === 'ArrowDown'){
      const max = AddNewline.length - 1;
      const Next = numb + 1
      if ( Next <= max ){
        this['Input'+ Next].focus();
        e.preventDefault();
      }
    }
  }

  async SetMessage(value, index){
    const AddNewline = JSON.parse(JSON.stringify(this.state.lineData))
    AddNewline[index].Word = value
    this.setState({lineData: AddNewline})
  }

  async SendMessage(){
    const AddNewline = JSON.parse(JSON.stringify(this.state.lineData))
    
    let Message = [];
    if(AddNewline.length !== 1) {
      AddNewline.forEach((item => {
        Message.push(item.Word + '\n')
      }))
    }else{
      Message.push(AddNewline[0].Word)
    }
    const Combine = Message.join('')
    if (AddNewline[0].Word !== '') {
      const SendMessageResult = await SendMessage(
        this.props.username,
        this.state.ChatUserID === this.props.userID ? this.props.username : 'Company',
        Combine,
        'message',
        this.state.ChatUserID === this.props.userID ? 'Company' : this.state.ChatUserID,
        this.props.token,
      );
      if(SendMessageResult.message === 'Success') {
        this.MessageToUserFunction(this.state.selecteduserindex);
        this.setState({
          lineData: [{
            Word: '',
          },]}, ()=>{
          clearTimeout(timer)
          timer = setTimeout(() => {
            this.FocusHere.executeScroll();
          }, 500);
        })
      }
    }

    if(this.state.file.length !== 0) {
      let sendBy =
      this.props.role === 'Da' || this.props.role === 'Agent'
        ? this.state.UserOnBehalf
        : this.props.username;

      const file = []
      for (let i = 0; i < this.state.Base64file.length; i += 1) {
        if (this.state.Base64file[i].startsWith('data:image/jpeg;base64,')) {
          file.push({
            filetype: 'jpeg',
            base64: this.state.Base64file[i].substring(23, this.state.Base64file[i].length)
          })
        } else if (this.state.Base64file[i].startsWith('data:image/png;base64,')) {
          file.push({
            filetype: 'png',
            base64: this.state.Base64file[i].substring(22, this.state.Base64file[i].length)
          })
        } else if (this.state.Base64file[i].startsWith('data:application/pdf;base64,')) {
          file.push({
            filetype: 'pdf',
            base64: this.state.Base64file[i].substring(28, this.state.Base64file[i].length)
          })
        }
      }

      const SendMessageResult = await SendMessage(
        this.props.username,
        sendBy,
        file,
        'image',
        this.state.ChatUserID === this.props.userID ? 'Company' : this.state.ChatUserID,
        this.props.token,
      );
      if (SendMessageResult.message === 'Success') {
        this.MessageToUserFunction(this.state.selecteduserindex);
        this.setState({ file: [], Base64file: [], BooleanSendImage: false }, ()=>{
          clearTimeout(timer)
          timer = setTimeout(() => {
            this.FocusHere.executeScroll();
          }, 500);
        })
      }
    }
    if (this.state.Base64Mp3 !== '') {
      let sendBy =
      this.props.role === 'Da' || this.props.role === 'Agent'
        ? this.state.UserOnBehalf
        : this.props.username;
      const SendMessageResult = await SendMessage(
        this.props.username,
        sendBy,
        this.state.Base64Mp3,
        'audio',
        this.state.ChatUserID === this.props.userID ? 'Company' : this.state.ChatUserID,
        this.props.token,
      );
      if (SendMessageResult.message === 'Success') {
        this.MessageToUserFunction(this.state.selecteduserindex);
        this.setState({  Base64Mp3: '', BooleanSendImage: false }, ()=>{
          clearTimeout(timer)
          timer = setTimeout(() => {
            this.FocusHere.executeScroll();
          }, 500);
        })
      }
      // this.MessageToUserFunction(this.state.selecteduserindex);
    }
  }

  async MessageToUserFunction(index){
    const userID = this.state.FilterListUser[index].ID;
    // this.setState({ userChat:  `${this.state.ListUser[index].Username}Listener`})
    const getChatResult = await getChatHistoryf(
      this.props.username,
      userID,
      this.state.CountMessage,
      this.props.token,
      moment(this.props.date).format('YYYY-MM-DD'),
    );
    // console.log(getChatResult)
    getChatResult.sort((a, b) => (a.color > b.color ? 1 : -1));
    for (let i = 0; i < getChatResult.length; i += 1) {
      getChatResult[i] = {...getChatResult[i], Sended: 'Completed'};
    }
    const BooleanPrevious = getChatResult.length !== 0 ? (getChatResult[getChatResult.length - 1].Total === getChatResult.length) : true

    localStorage.setItem('UrlImages', JSON.stringify(getChatResult));
    this.setState({
      selecteduserindex: index,
      ShowUSersList: !this.state.ShowUSersList,
      ChatArrayHistory: getChatResult, 
      ViewPrevious: !BooleanPrevious, 
      DisplayUser: this.state.FilterListUser[index].Username, 
      ChatUserID: userID.toString(),
      userChat: `${this.state.FilterListUser[index].Username === 'Company' ? this.props.username : this.state.FilterListUser[index].Username}Listener`, 
      userChatname: this.state.FilterListUser[index].Username
    }, ()=>{
      this.HandleSocket();
      if((this.state.ChatArrayHistory).length > 0){
        clearTimeout(timer)
        timer = setTimeout(() => {
          this.FocusHere.executeScroll();
        }, 10);
      }
      // if (this.state.Base64Mp3 !== '') {
      //   let ChatArrayHistory = this.state.ChatArrayHistory
      //   ChatArrayHistory.push({DateTime: '2023-05-12 15:30:11', ID: 1080, Sended: 'Completed', adminUsername: 'JXADMIN', chatFrom: '178', chatTo: 'Company', context: 'https://ace-bt.s3.amazonaws.com/2023-05-16___17-29-37-23___Testing.mp3', type: 'Audio'})
      //   ChatArrayHistory.push({DateTime: '2023-05-12 15:30:11', ID: 1079, Sended: 'Completed', adminUsername: 'JXADMIN', chatFrom: 'Company', chatTo: '178', context: 'https://ace-bt.s3.amazonaws.com/2023-05-16___17-29-37-23___Testing.mp3', type: 'Audio'})
      //   this.setState({ ChatArrayHistory }, () => {
      //     // this.getAudioDisplay(this.state.ChatArrayHistory);
      //   })
      // }
    });
  }

  HandleSocket() {
    this.socket.removeAllListeners();
    this.socket.on(this.state.userChat, data => {
      if (data) {
        this.LatestMessageReceive(data)
      }
    });
  }

  async SeePreviousMessage(){
    this.setState({ CountMessage: this.state.CountMessage + 10}, async ()=>{
      const getChatResult = await getChatHistoryf(
        this.props.username,
        this.state.ChatUserID,
        this.state.CountMessage,
        this.props.token,
        moment(this.props.date).format('YYYY-MM-DD')
      );
      getChatResult.sort((a, b) => (a.color > b.color ? 1 : -1));
      for (let i = 0; i < getChatResult.length; i += 1) {
        getChatResult[i] = {...getChatResult[i], Sended: 'Completed'};
      }

      const BooleanPrevious = getChatResult.length !== 0 ? (getChatResult[getChatResult.length - 1].Total === getChatResult.length) : true

      this.setState({ChatArrayHistory: getChatResult, ViewPrevious: !BooleanPrevious,})
    })
  }

  enter(e) {
    if(e.key === 'Enter') {
      this.filterUser()
    }
  }
  
  swapItems(array, a, b) {
    array[a] = array.splice(b, 1, array[a])[0];
    return array;
  }

  filterUser() {
    let FilterListUser = JSON.parse(JSON.stringify(this.state.ListUser))

    if (this.state.SearchUser !== '') {
      FilterListUser = FilterListUser.filter((val) => val.Username.toLowerCase().includes(this.state.SearchUser.toLowerCase()))
    }
    if (this.state.filterUserRole) {
      FilterListUser = FilterListUser.filter((val) => val.Role === this.state.filterUserRole)
    }
    if (FilterListUser.length >= 1 && FilterListUser.findIndex((item) => item.Username === 'Company')) {
      FilterListUser = this.swapItems(FilterListUser, 0, FilterListUser.findIndex((item) => item.Username === 'Company'))
    }
    
    this.setState({ FilterListUser })
  }
  

  async LatestMessageReceive(data){
    data.SuccessMsg.forEach(item => {
      if (item.SendBy === 'Company') {
        const AddMessage = JSON.parse(
          JSON.stringify(this.state.ChatArrayHistory),
        );
        const preparedSend = {
          DateTime: new Date(),
          chatFrom: item.SendBy,
          chatTo: item.SendTo,
          context: `${item.Message}`,
          type: item.Type,
          adminUsername: item.adminUsername,
        };
        AddMessage.push(preparedSend);
        this.setState({
          ChatArrayHistory: AddMessage,
        }, ()=>{
          clearTimeout(timer)
          timer = setTimeout(() => {
            this.FocusHere.executeScroll();
          }, 10);
        });
      } 
      if (item.SendTo === 'Company') {
        const AddMessage = JSON.parse(
          JSON.stringify(this.state.ChatArrayHistory),
        );
        const preparedSend = {
          DateTime: new Date(),
          chatFrom: this.state.ChatUserID,
          chatTo: item.SendBy,
          context: `${item.Message}`,
          type: item.Type,
        };
        AddMessage.push(preparedSend);
        this.setState({
          ChatArrayHistory: AddMessage,
        }, ()=>{
          clearTimeout(timer)
          timer = setTimeout(() => {
            this.FocusHere.executeScroll();
          }, 10);
        });
      }
    })
  }

  handleScroll = (e) => {
    const window = e.target;
    if (window.scrollTop === 0 && this.state.ViewPrevious) {
      this.SeePreviousMessage()
    }
  }

  showFileUpload() {
    this.fileUpload.current.click();
  }

  uploadMultipleFiles(e) {
    this.fileArray = []
    this.fileObj = []
    this.Base64 = []
    if (e.target.files.length > 0) {
      this.fileObj.push(e.target.files)
      for (let i = 0; i < this.fileObj[0].length; i++) {
        this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
        getBase64(this.fileObj[0][i]).then(
          Base64data => this.Base64.push(Base64data)
        )
      }
      this.setState({ file: this.fileArray, Base64file: this.Base64, BooleanSendImage: true })
    }
  }

  handleCloseImage() {
    this.setState({
      SelectedImageModal: false,
      SelectedImage: '',
    })
  }

  async DownloadPDFFile(DataDetials) {
    // const link = DataDetials.Url.replace('https://scan-prd.s3.ap-southeast-1.amazonaws.com', 'https://d1egw2l1nerbt8.cloudfront.net').replace('https://scan-prd.s3.amazonaws.com', 'https://d1egw2l1nerbt8.cloudfront.net')
    const link = DataDetials.Url.replace('https', 'http')
    fetch(link)
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = link.replace(/^.*[\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
    // const base64 = await getBase64API(this.props.username, this.props.token, DataDetials.Url)
    // const blob = this.b64toBlob(base64.base64, 'png', 512);
    // let blobUrl = window.URL.createObjectURL(blob);
    // let a = document.createElement('a');
    // a.download = DataDetials.Url.replace(/^.*[\\/]/, '');
    // a.href = blobUrl;
    // document.body.appendChild(a);
    // a.click();
    // a.remove();
  }

  async StartRecord2() {
    if (this.state.Recoreddd) {
      recorders
        .stop()
        .getMp3().then(([buffer, blob]) => {
          const file = new File(buffer, 'me-at-thevoice.mp3', {
            type: blob.type,
            lastModified: Date.now()
          });
          getBase64(file).then(
            Base64data => this.setState({ Base64Mp3: Base64data }, () => {
              this.SendMessage();
            })
          )
          const player = new Audio(URL.createObjectURL(file));
          player.play();
          this.setState({ Recoreddd: false, recordings: player, AudioArray: URL.createObjectURL(file)})
        }).catch((e) => {
          // alert('We could not retrieve your message');
          console.log(e);
        });
    } else {
      recorders.start().then(() => {
        this.setState({ Recoreddd: true })
      }).catch((e) => {
        console.error(e);
      });
    }
  }

  DeleteRecord() {
    recorders
      .stop()
      .getMp3().then(() => {
        this.setState({ Recoreddd: false, recordings: '', AudioArray: '', Base64Mp3: ''})
      }).catch((e) => {
        // alert('We could not retrieve your message');
        console.log(e);
      });
  }

  ReturnID(getitem) {
    return `playlist${getitem.ID}`
  }

  // getAudioDisplay(ChatArrayHistory) {
  //   var newArray = ChatArrayHistory.filter(function (el) {
  //     return el.type === 'Audio'
  //   });
  //   newArray.forEach((items) => {
  //     const player = new Audio('https://ace-bt.s3.amazonaws.com/2023-05-16___17-29-37-23___Testing.mp3')
  //     const li = document.createElement('li');
  //     player.controls = true;
  //     li.appendChild(player);
  //     document.querySelector(`#playlist${items.ID}`).appendChild(li);
  //   })
  // }

  render() {
    let chatHistory = this.state.ChatArrayHistory
    if (this.state.browserType === 'firefox') {
      chatHistory = chatHistory.reverse()
    }
    return (
      <div>
        <Header/>
        <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 80, width: '70%', left: 250}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        {/* {this.state.SelectedImageModal === true && 
        <OpenSelectedImg SelectedImageModal={this.state.SelectedImageModal} handleCloseImage={this.handleCloseImage} SelectedImage={this.state.SelectedImage} DownloadPDFFile={this.DownloadPDFFile}/>
        } */}
        <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 56px)'}}>
          <div style={{ display: 'flex', flexDirection: 'column', width: (isMobile) ? (this.state.ShowUSersList === true) ? '100%' : '0%' : '20%'}} >
            {(isMobile) && this.state.ShowUSersList === false ? (
              <></>
            ) : (
              <>
                <div style={{ display: 'flex', flexDirection: 'column', padding: 10,  border: '1px solid #ddd'}}>
                  <div style={{ fontWeight: 'bold' }}>{this.props.username}</div>
                  <div style={{ display: 'flex', padding: 5, flexDirection: 'column' }}>
                    <Form.Control 
                      className='ChatSearchTextbox'
                      value={this.state.SearchUser}
                      type={'text'} 
                      onChange={(e) => this.setState({ SearchUser: e.target.value })}
                      placeholder={'Search'}
                      onKeyDown={(e) => this.enter(e)}
                    />
                    <div style={{ display: 'flex'}}>
                      <Form.Select style={{ width: 150}} value={this.state.filterUserRole} onChange={(e) => this.setState({ filterUserRole: e.target.value }, () => this.filterUser())}>
                        <option></option>
                        <option value={'Da'}>Da</option>
                        <option value={'Agent'}>Agent</option>
                        <option value={'Player'}>Player</option>
                      </Form.Select>
                      <Button onClick={() => this.filterUser()}>寻找</Button>
                    </div>
                  </div>
                </div>
                <div className='scrollbar-hidden' style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', height: '100%'}}>
                  <ListGroup style={{ marginBottom: '1rem' }}>
                    <TransitionGroup>
                      {this.state.FilterListUser.map((items,index) => {
                        return(
                          <CSSTransition
                            key={index}
                            timeout={500}
                            classNames="item"
                          >
                            <ListGroup.Item  action variant="light"  onClick={() => this.MessageToUserFunction(index)} style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', justifyContent: 'space-between', padding: 10, width: '100%' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', width: '75%'}}>
                                <div style={{ fontSize: 16, fontWeight: 'bold'}}>
                                  {items.Username}
                                </div>
                                {items.Message && <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}> 
                                  {items.Message.type === 'image' ? '<image>' : items.Message.context}
                                </div>}
                              </div>
                              {items.Message && <div style={{ display: 'flex', flexDirection: 'column', fontSize: 16, fontWeight: 'bold', width: '20%'}}>
                                <div style={{ color: 'green' }}>{items.Role}</div>
                                <div>{items.Message.DateTime ? moment(items.Message.DateTime).format('DD/MM') : '-'}</div>
                              </div>}
                            </ListGroup.Item >
                          </CSSTransition>
                        )
                      })}
                    </TransitionGroup>
                  </ListGroup>
                </div>
              </>
            )}
          </div>
          {(this.state.DisplayUser !== '') ? <div style={{ width: (!isMobile) ? '80%' : (this.state.ShowUSersList === true) ? '0%' : '100%', height: '100%' }}>
            {(isMobile) && this.state.ShowUSersList === true ? (
              <></>
            ) : (
              <>
                <div style={{ display: 'flex', paddingLeft: 10, alignItems: 'center', height: 50, flexDirection: 'row', fontWeight: 'bold',  border: '1px solid #ddd'}}>
                  {(isMobile) && 
                    <div style={{ marginRight: 5, cursor: 'pointer', fontSize: '30px'}} onClick={() => this.setState({ ShowUSersList: !this.state.ShowUSersList})}>{'🔙'}</div>
                  }
                  <div>{this.state.DisplayUser}</div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 110px)', overflowY: 'auto', backgroundColor: '#F5F2EC' }} onScroll={this.handleScroll}>
                  <div className="dcc" style={{ width: '100%'}}>
                    {(this.state.ViewPrevious)&&<Button variant="light" style={{ width: '40%', padding: 5, color: 'grey' }} 
                      onClick={() => this.SeePreviousMessage()} >
                      {'Syncing older messages. Click to see progress.'}</Button>}
                  </div>
                  {chatHistory.map((item, index) => {
                    return (
                      <div key={index} style={{ display: 'flex', flexDirection: 'column'}}>
                        <div style={{ display: 'flex', justifyContent: (((this.props.role === 'Da' || this.props.role === 'Agent' || this.props.role === 'Player') && item.chatFrom !== 'Company') || (this.props.role !== 'Da' && this.props.role !== 'Agent' && this.props.role !== 'Player' && item.chatFrom === 'Company')) ? 'right' : 'left' }}>
                          {(item.type === 'message') 
                            ? <div className='MessageContainer' style={{ backgroundColor: ((this.props.role === 'Da' || this.props.role === 'Agent' || this.props.role === 'Player') && item.chatFrom !== 'Company') || (this.props.role !== 'Da' && this.props.role !== 'Agent' && this.props.role !== 'Player' && item.chatFrom === 'Company') ? 'white' : '#D9FDD3', whiteSpace: 'pre-wrap' }}>
                              <div>{moment(item.DateTime).format('DD/MM HH:mm')}{item.chatFrom === 'Company' ? ` - ${(item.adminUsername === undefined) ? this.props.username : item.adminUsername}` : ''}</div>
                              {item.context}
                            </div> 
                            : (item.type === 'image') ? <div className='MessageContainer' style={{ backgroundColor: ((this.props.role === 'Da' || this.props.role === 'Agent' || this.props.role === 'Player') && item.chatFrom !== 'Company') || (this.props.role !== 'Da' && this.props.role !== 'Agent' && this.props.role !== 'Player' && item.chatFrom === 'Company') ? 'white' : '#D9FDD3', whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column' }}>
                              <div>{moment(item.DateTime).format('DD/MM HH:mm')}{item.chatFrom === 'Company' ? ` - ${(item.adminUsername === undefined) ? this.props.username : item.adminUsername}` : ''}</div>
                              {/* <WindowOpener url={`OpenWindowReceipt?ID=${'none'}&DisplayImg=${item.context}`} width={'auto'} height={'auto'}>
                            <img src={item.context} alt="img"
                            // onClick={() => this.setState({ SelectedImageModal: true, SelectedImage: { Url: item.context } })}
                              style={{ width: '50%' , height: '100%', cursor: 'pointer'}}
                            />
                          </WindowOpener> */}
                              <a href={`OpenSelectedImg?ID=${item.ID}&DisplayImg=${item.context}&SelectedIndex=${index}`} target="_blank" rel="noreferrer"><img src={item.context} alt="img"
                                // onClick={() => this.setState({ SelectedImageModal: true, SelectedImage: { Url: item.context } })}
                                style={{ width: '50%' , height: '100%', cursor: 'pointer'}}
                              /></a>
                            </div> : 
                              // <div><ul id={this.ReturnID(item)}></ul></div>
                              <div className='MessageContainer' style={{ marginTop: 5, display: 'flex', flexDirection: 'column', marginLeft: 5, backgroundColor: ((this.props.role === 'Da' || this.props.role === 'Agent' || this.props.role === 'Player') && item.chatFrom !== 'Company') || (this.props.role !== 'Da' && this.props.role !== 'Agent' && this.props.role !== 'Player' && item.chatFrom === 'Company') ? 'white' : '#D9FDD3' }}>
                                <div style={{ right: 0 }}>{`${moment(item.DateTime).format('DD/MM HH:mm')}${item.chatFrom === 'Company' ? ` - ${(item.adminUsername === undefined) ? this.props.username : item.adminUsername}` : ''}`}</div>
                                <ReactAudioPlayer
                                  src={`${item.context}`}
                                  // autoPlay
                                  controls
                                />
                              </div>
                          }
                        </div>
                      </div>
                    )})}
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', color: '#aaa'}} ref={this.FocusHere.elRef}>End of Chat</div>
                  {/* <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }} ref={this.FocusHere.elRef}>
                    <img src={'https://i.gifer.com/origin/a1/a151e29409954830f542a9b8bb604582.gif'} alt="img" style={{ width: 200 , height: '50%', }}/>
                  </div> */}
                </div>
                {(((this.props.role === 'Da' || this.props.role === 'Agent') && this.props.userID !== this.state.ChatUserID)) ? <div></div> : <div style={{ width: '100%', height: 60, display: 'flex', justifyContent: 'space-between', backgroundColor: '#ddd', padding: 5 }}>
                  <div className='scrollbar-hidden' style={{ width: '90%', border: '1px solid #ddd',  maxHeight: '150px',  borderRadius: 10 }}>
                    {this.state.lineData.map((item, idx) => {
                      return<input className='inputOutline'
                        key={idx}
                        id={`BetText`}
                        onKeyDown={event => {
                          this.keydownHandler(event, idx);
                        }}
                        value={`${item.Word}`}
                        ref={(input) => { this['Input' + idx] = input; }}
                        onChange={(e) => this.SetMessage(e.target.value, idx)}
                        placeholder={  (this.state.lineData.length === 1 && (idx === 0) && (item.Word === '') ? 'Type a message' : null)}
                        style={{  width: '90%', border: '1px solid white', padding: 10}}/>
                    })}
                  </div>

                  <Button  disabled={true} style={{ width: 200,  marginRight: 5, background: (this.state.Recoreddd === true) ? 'black' : ''}}>
                    {this.state.Recoreddd === false && 
                    <>
                     Total Image: {this.state.file.length}
                    </>}
                    {this.state.Recoreddd === true && 
                    <>
                      <img src={'https://nesotechnology.com/wp-content/uploads/2023/05/ezgif.com-gif-maker.gif'} alt="img" style={{ width: '100%' , height: '100%', }}/>
                    </>}
                  </Button>
                  {(this.props.role === 'Da' || this.props.role === 'Agent') && <div style={{ display: 'flex'}}>
                    <Form.Select style={{ width: 150}} disabled={this.state.DisplayUser === 'Company' ? false : true} value={this.state.UserOnBehalf} onChange={(e) => this.setState({ UserOnBehalf: e.target.value }, () => this.filterUser())}>
                      <option></option>
                      {this.state.ListAllowUserUpload.map((item, idx) => {
                        return <option key={idx} value={item.Username}>{item.Username}</option>})}
                    </Form.Select>
                  </div>}
                  {/**/}
                  {this.state.Recoreddd === true && 
                  <Button onClick={() => this.DeleteRecord()} style={{ width: 55, height: 50 , backgroundColor: 'red', borderRadius: 50, marginRight: 5}} >
                    <img src={'https://cdn-icons-png.flaticon.com/512/5499/5499405.png'} alt="img" style={{ width: '100%', color: 'white' }}/>
                  </Button>
                  }
                  <Button onClick={() => this.StartRecord2()} style={{ width: 55, height: 50 , backgroundColor: (this.state.Recoreddd === true) ? 'red' : 'rgb(14, 149, 202)', borderRadius: 50, marginRight: 5}} >
                    <img src={'https://cdn-icons-png.flaticon.com/512/1082/1082810.png'} alt="img" style={{ width: '100%', color: 'white' }}/>
                  </Button>
                  <Button onClick={this.showFileUpload} style={{ width: 55, height: 50 , backgroundColor: 'rgb(14, 149, 202)', borderRadius: 50, marginRight: 5}} >
                    <img src={'https://aptira.com/wp-content/uploads/2015/04/cloud_upload.png'} alt="img" style={{ width: '100%' , height: '100%', }}/>
                  </Button>
                  <input hidden={true} type="file" accept=".pdf,.jpg,.png,.jpeg" className="form-control"  ref={this.fileUpload} onChange={this.uploadMultipleFiles} multiple />
                  <Button onClick={() => this.SendMessage()} disabled={this.state.BooleanSendImage ? false : true} style={{ width: 55, height: 50 , backgroundColor: 'rgb(14, 149, 202)', borderRadius: 50, marginRight: 5}} >
                    <img src={'https://cdn-icons-png.flaticon.com/512/660/660620.png'} alt="img" style={{ width: '100%' , height: '100%', backgroundColor: 'rgb(14, 149, 202)'}}/>
                  </Button>
                </div>}
              </>
            )}
          </div> 
            : 
            <>
              <div style={{ width: (!isMobile) ? '65%' : '0%', height: '100%' }}>
                <div className='dcc' style={{ width: '100%', height: 'calc(90% - 10px)', overflowY: 'auto' }}>
                  <img src={'https://static.thenounproject.com/png/4601660-200.png'} alt="img"
                    style={{ width:  150  , height:  150 }}/>
                </div>
              </div> 
            </>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    role: state.role,
    date: state.date,
  };
}

export default connect(mapStateToProps, null)(Chating);
