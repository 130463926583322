import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import { Container, Form, Button, Table, Modal, Alert } from 'react-bootstrap';
import { getAgentList, getAdminList, createUser, updateUser, deleteUser } from '../Api';
import Header from '../Component/Header';
import moment from 'moment-timezone';

var timer

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addUserModalShow: false,
      userList: [],
      userObj: [],
      filterUser: '',
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      UpdateDetails: false,
      activeUpdateID: '',

      createUserUsername: '',
      createUserName: '',
      createUserPassword: '',
      createUserRole: '',
      createUserDownline: [],
      createUserActive: true,

      SearchbyRole: '',
      SearchbyUsername: '',
      SearchbyName: '',

      OpenDeleteModal: false,
      ConfirmPassword: '',
      DetailsID: '',
      allList: {},
      allListArray: [],
      downlineOption: [],
      downlineSelected: '',

      agentList: [],
    }
    this.handleClose = this.handleClose.bind(this)
  }

  async componentDidMount() {
    this.StartAdminList();
  }

  async StartAdminList() {
    this.initUser()
  }

  async initUser() {
    const userList = await getAdminList(this.props.username, this.props.token, 'details')

    const allList = {}
    userList.forEach((item) => {
      allList[item.ID] = item.Username
    })

    let agentList = await getAgentList(this.props.username, this.props.token, '', moment(this.props.date).format('YYYY-MM-DD'))
    agentList = agentList.map((item) => ({
      ...item,
      selected: false,
    }))
    this.setState({ userList, allList, allListArray: userList, agentList });
  }
  async SearchUser() {
    const searchObj = {
      SearchbyRole: this.state.SearchbyRole,
      SearchbyUsername: this.state.SearchbyUsername,
      SearchbyName: this.state.SearchbyName,
    }
    const userList = await getAdminList(this.props.username, this.props.token, 'details', searchObj)
    this.setState({ userList });
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 5000);
  };

  handleClose() {
    this.setState({ 
      addUserModalShow: false,
      UpdateDetails: false,
      OpenDeleteModal: false,
      createUserUsername: '',
      createUserName: '',
      createUserPassword: '',
      createUserRole: '',
      createUserDownline: [],
      createUserActive: true,
      DetailsID: '',
      ConfirmPassword: '',
    })
  }

  ChangeUserDetails(details) {
    let downlineOption = []
    if (details.Role  === 'Admin') {
      downlineOption = this.state.allListArray.filter((item) => item.Role === 'Neiwu')
    } else if (details.Role === 'Neiwu') {
      downlineOption = this.state.allListArray.filter((item) => item.Role === 'Bumen')
    } else if (details.Role === 'Bumen') {
      downlineOption = this.state.allListArray.filter((item) => item.Role === 'Zu')
    } else if (details.Role === 'Zu') {
      downlineOption = this.state.allListArray.filter((item) => item.Role === 'Da')
    } else if (details.Role === 'Da') {
      downlineOption = this.state.allListArray.filter((item) => item.Role === 'Agent')
    } else if (details.Role === 'Agent') {
      downlineOption = this.state.allListArray.filter((item) => item.Role === 'Player')
    }
    this.setState({
      activeUpdateID: details.ID, 
      createUserUsername: details.Username, 
      createUserName: details.Name, 
      createUserPassword: details.Password, 
      createUserRole: details.Role, 
      createUserDownline: details.Downline, 
      createUserActive: details.Active,
      createUserTime: details.ClosingTime, 
      downlineOption,
      addUserModalShow: true, 
      UpdateDetails: true, 
    })
  }
  
  ChangeRole(value) {
    let downlineOption = []
    if (value === 'Admin') {
      downlineOption = this.state.allListArray.filter((item) => item.Role === 'Neiwu')
    } else if (value === 'Neiwu') {
      downlineOption = this.state.allListArray.filter((item) => item.Role === 'Bumen')
    } else if (value === 'Bumen') {
      downlineOption = this.state.allListArray.filter((item) => item.Role === 'Zu')
    } else if (value === 'Zu') {
      downlineOption = this.state.allListArray.filter((item) => item.Role === 'Da')
    }
    this.setState({
      createUserDownline: [],
      createUserRole: value, 
      downlineOption,
      createUserUpline: '',
    })
  }

  async DeleteUsers(details) {
    this.setState({OpenDeleteModal: true, DetailsID: details.ID})
  }

  async ConfirmDelete() {
    const ConfirmPassword = this.state.ConfirmPassword
    if(ConfirmPassword === '') {
      this.openNotification('Wrong Password','error')
    } else {
      const deleteUserResult = await deleteUser(this.props.username, this.state.DetailsID, ConfirmPassword, this.props.token)
      if (deleteUserResult.message === 'Wrong Password') {
        this.openNotification('删除失败, 密码错误', 'error')
      } else {
        if (deleteUserResult.message === 'User Deleted Successful') {
          this.openNotification('删除成功', '')
          this.handleClose()
          this.SearchUser()
        } else {
          this.openNotification('删除失败', 'error')
        }
      }
    }
  }

  async createUserF() {
    if (this.state.createUserUsername === '') {
      this.openNotification('空用户名', 'error')
    } else if (this.state.createUserPassword === '') {
      this.openNotification('空密码', 'error')
    } else if (this.state.createUserRole === '') {
      this.openNotification('空角色', 'error')
    } else if (this.state.createUserRole === 'Player' && this.state.createUpperline === ''){
      this.openNotification('请选择上线', 'error')
    } else if (this.state.createUserRole === 'Agent' && this.state.createUpperline === ''){
      this.openNotification('请选择上线', 'error')
    } else {
      let createUserObject;
      if (this.state.createUserRole === 'Da' || this.state.createUserRole === 'Agent' || this.state.createUserRole === 'Player' ) {
        createUserObject = {
          Username: this.state.createUserUsername,
          Name: this.state.createUserName,
          Password: this.state.createUserPassword,
          Role: this.state.createUserRole,
          Downline: this.state.createUserDownline,
          Active: (this.state.createUserActive) ? 1 : 0,
        } 
      }else {
        createUserObject = {
          Username: this.state.createUserUsername,
          Name: this.state.createUserName,
          Password: this.state.createUserPassword,
          Role: this.state.createUserRole,
          Downline: this.state.createUserDownline,
          Active: (this.state.createUserActive) ? 1 : 0,
        }
      }
      const createUserResult = await createUser(this.props.username, this.props.token, createUserObject)
      if (createUserResult.message && createUserResult.message === 'User Created Successful') {
        this.openNotification('增加成功', '')
        this.initUser()
        this.setState({ UpdateDetails: false, SearchbyRole: '', SearchbyUsername: '', SearchbyName: '', createUpperline: '', createUserRole: '', createUserTime: moment("19:00", "HH:mm").format("HH:mm") });
        this.handleClose()
      }
      if (createUserResult.error === 'Duplicated Username, Please Choose Another'){
        this.openNotification('用户名重复，请另选', '')
      }
    }
  }

  async UpdateUsersDetails() {
    if (this.state.createUserRole === 'Player' && this.state.createUpperline === ''){
      this.openNotification('请选择上线', 'error')
    } else {
      let Passobject = {
        ID: this.state.activeUpdateID,
        Username: this.state.createUserUsername,
        Name: this.state.createUserName,
        Password: this.state.createUserPassword,
        Role: this.state.createUserRole,
        Downline: this.state.createUserDownline,
        Upline: null,
        Active: (this.state.createUserActive) ? 1 : 0,
      }
      const updateUserResult = await updateUser(this.props.username, this.props.token, Passobject)
      
      if (updateUserResult.message && updateUserResult.message === 'User Updated Successful') {
        this.openNotification('更新成功', '')
        this.setState({ createUpperline: '', createUserRole: '', createUserTime: moment("19:00", "HH:mm").format("HH:mm")});
        this.handleClose()
        this.SearchUser()
      } else if(updateUserResult.error === 'Time Out Upload'){
        this.openNotification(`${updateUserResult.error}`, 'error')
      } else {
        this.openNotification('更新失败', 'error')
      }
    }
  }

  SearchbyRole(e) {
    if (e.key === 'Enter') {
      this.setState({SearchbyUsername: '', SearchbyName: '' }, () => this.SearchUser())
    }
  }

  SearchbyUsername(e) {
    if (e.key === 'Enter') {
      this.setState({SearchbyRole: '', SearchbyName: '' }, () => this.SearchUser())
    }
  }
  SearchbyName(e) {
    if (e.key === 'Enter') {
      this.setState({SearchbyRole: '', SearchbyUsername: '' }, () => this.SearchUser())
    }
  }

  handleChangeDownline(id) {
    let copyArray = JSON.parse(JSON.stringify(this.state.createUserDownline))
    if (this.state.createUserDownline.includes(id)) {
      copyArray = copyArray.filter((item) => item !== id)
    } else {
      copyArray.push(id)
    }
    this.setState({ createUserDownline: copyArray })
  }

  translateRole(value) {
    if (value === 'Admin') {
      return 'Admin'
    } else if (value === 'Neiwu') {
      return '内务'
    } else if (value === 'Bumen') {
      return '部门'
    } else if (value === 'Zu') {
      return '组'
    }
  }
  render() {
    return (
      <div fluid style={{ fontFamily: 'cursive' }}>
        <Header />
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <Modal centered show={this.state.addUserModalShow} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {(this.state.UpdateDetails === true) ? `更换用户资料` :`增加用户`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>Username</div>
              <Form.Control style={{ width: 150}} value={this.state.createUserUsername} onChange={(e) => this.setState({ createUserUsername: e.target.value })} />
            </div>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>名字</div>
              <Form.Control style={{ width: 150}} value={this.state.createUserName} onChange={(e) => this.setState({ createUserName: e.target.value })} />
            </div>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>密码</div>
              <Form.Control style={{ width: 150}} value={this.state.createUserPassword} onChange={(e) => this.setState({ createUserPassword: e.target.value })} />
            </div>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>活跃</div>
              <Form.Check type="checkbox" checked={this.state.createUserActive} onChange={(e) => this.setState({ createUserActive: e.target.checked})}/>
            </div>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>角色</div>
              <Form.Select style={{ width: 150}}  disabled={this.state.UpdateDetails} value={this.state.createUserRole} onChange={(e) => this.ChangeRole(e.target.value)}>
                <option></option>
                <option value={'Admin'}>Admin</option>
                <option value={'Neiwu'}>内务</option>
                <option value={'Bumen'}>部门</option>
                <option value={'Zu'}>组</option>
              </Form.Select>
            </div>
            {<div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>下线</div>
              <div style={{ padding: 10, width: 200, border: '1px solid #ccc'}}>
                {
                  this.state.downlineOption.map((item, index) => 
                    <div key={index}  style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <div style={{ marginRight: 10 }}>{item.Username}</div>
                      <Form.Check type="checkbox" disabled={(this.state.createUserRole === 'Zu')} checked={this.state.createUserDownline.includes(item.ID)} onChange={(e) => this.handleChangeDownline(item.ID)}/>
                    </div>
                  )
                }
              </div>
            </div>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => this.handleClose()}>关闭</Button>
            {this.state.UpdateDetails === true ?
              <Button variant="success" onClick={() => this.UpdateUsersDetails()}>提交</Button>
              : 
              <Button variant="success" onClick={() => this.createUserF()}>提交</Button>
            }
          </Modal.Footer>
        </Modal>
        <Modal centered show={this.state.TimeModalShow} onHide={()=> this.setState({ TimeModalShow: false})}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {'关闭时间'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>关闭时间</div>
              <Form.Control value={this.state.createUserTime} onChange={(e) => this.setState({ createUserTime: e.target.value})} type="time" style={{ marginRight: 10, width: 180 }}/>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => this.setState({ TimeModalShow: false})}>关闭</Button>
            <Button variant="success" onClick={() => this.setState({ TimeModalShow: false})}>提交</Button>
          </Modal.Footer>
        </Modal>
        <Modal centered show={this.state.OpenDeleteModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirm Delete
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>Username</div>
              <Form.Control style={{ width: 150}} value={this.props.username} disabled={true}/>
            </div>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>确认密码</div>
              <Form.Control style={{ width: 150}} value={this.state.ConfirmPassword} onChange={(e) => this.setState({ ConfirmPassword: e.target.value })} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => this.handleClose()}>关闭</Button>
            <Button variant="danger" onClick={() => this.ConfirmDelete()}>确定删除</Button>
          </Modal.Footer>
        </Modal>
        <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
          {/* <marquee>sss</marquee> */}
          <div style={{ display: 'flex', alignItems: 'center', width: '80%' }}>
            <h2 className='TitleSize'>用户名单</h2>
            <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Username} </div>
            <Form.Control style={{ width: 100, marginRight: 10}} onKeyDown={(e) => this.SearchbyUsername(e)} value={this.state.SearchbyUsername} onChange={(e) => this.setState({SearchbyUsername: e.target.value})}>

            </Form.Control>
            <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Name} </div>
            <Form.Control style={{ width: 100, marginRight: 10}} onKeyDown={(e) => this.SearchbyName(e)} value={this.state.SearchbyName} onChange={(e) => this.setState({SearchbyName: e.target.value})}>

            </Form.Control>
            <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Role} </div>
            <Form.Select style={{ width: 100, marginRight: 10}} value={this.state.SearchbyRole} onChange={(e) => this.setState({SearchbyRole: e.target.value})}>
              <option></option>
              <option value={'Admin'}>Admin</option>
              <option value={'Neiwu'}>内务</option>
              <option value={'Bumen'}>部门</option>
              <option value={'Zu'}>组</option>
            </Form.Select>
            {/* <Button style={{ minWidth: 80 }} onClick={() => this.SearchUser()} variant="primary">寻找</Button> */}
            <div class="wrap" style={{ minWidth: 80 }}>
              <button class="button2" onClick={() => this.SearchUser()}>{language[this.props.currentLanguage].Search}</button>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            {/* <Button style={{ minWidth: 80 }} onClick={() => this.setState({ addUserModalShow: true, downlineOption: [], createUserTime: moment("19:00", "HH:mm").format("HH:mm") })} variant="success">增加</Button> */}
            <div class="wrap" style={{ marginRight: 5 }}>
              <button class="buttonAdd" onClick={() => this.setState({ addUserModalShow: true, downlineOption: [], createUserTime: moment("19:00", "HH:mm").format("HH:mm") })}>增加</button>
            </div>
          </div>
        </div>
        <div className="tableFixed">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>用户名</th>
                <th>名字</th>
                <th>角色</th>
                <th>活跃</th>
                <th>下线</th>
                <th>行动</th>
              </tr>
            </thead>
            <tbody>
              {this.state.userList.map((item, index) => 
                (item.Role !== 'Player' && item.Role !== 'Agent' && item.Role !== 'Da') && <tr key={index}>
                  <td>{item.ID}</td>
                  <td>{item.Username}</td>
                  <td>{item.Name}</td>
                  <td>{this.translateRole(item.Role)}</td>
                  <td>{item.Active === 1 ? '✔️' : '❌'}</td>
                  <td>{item.displayDownline.map((item) => <div>{item}</div>)}</td>
                  <td style={{ display: 'flex' }}>
                    {/* <Button style={{ minWidth: 80 }} onClick={() => this.ChangeUserDetails(item)} variant="primary">换资料</Button> */}
                    <div class="wrap" style={{ marginRight: 5 }}>
                      <button class="button2" onClick={() => this.ChangeUserDetails(item)}>换资料</button>
                    </div>
                    {/* <Button style={{ minWidth: 80, marginLeft: 5 }} onClick={() => this.DeleteUsers(item)} variant="danger">删除户口</Button> */}
                    <div class="wrap" style={{ marginLeft: 5 }}>
                      <button class="buttonDelete" onClick={() => this.DeleteUsers(item)}>删除户口</button>
                    </div>
                  </td>
                </tr>)}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
    date: state.date,
    userID: state.userID,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
