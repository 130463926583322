import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { ExportPdf } from '../ExportPdf';
import { getBase64 } from '../Component/getBase64';
import { Form, Button, Table, Modal, Alert } from 'react-bootstrap';
// import OpenSelectedImg from '../Component/OpenSelectedImg';
import loading from '../image/loadings.gif';
import PDF1 from '../image/PDF2.png';
import { getPDFData, getAgentList, getDaAgentListF, getPlayerList, getAllPlayerListF, setMoveFile, deleteScanFNC, multiUpload, getBase64API } from '../Api'
import moment from 'moment-timezone';
import gridview from '../image/gridview.png';
import listview from '../image/listview.png';
import Header from '../Component/Header';
import { language } from '../language';
import Select from 'react-select'

var timer

class ScanList extends React.Component {

  fileObj = [];
  fileArray = [];
  Base64 = []

  constructor(props) {
    super(props);
    this.state = {
      UploadModal: false,
      userList: [],
      userListDA: [],
      userListPlayer: [],
      filterUser: '',
      filterAgent: '',
      filterDaA: '',
      pdfData: [],
      gridView: false,
      allSelected: false,
      moveFileID: [],
      moveFileUser: '',
      moveFileModal: false,
      playerList: [],
      DataQuantity: 0,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      Disabled: false,
      SelectedFrom: '',
      AgentListDisable: false,
      PlayerListDisable: false,
      file: [],
      Base64file: [],
      UploadLoading: false,
      SelectedUserName: '',
      SelectedImage: '',
      SelectedImageModal: false,
      ShowImage: false,
      highlighted: '',
      ConvertPDF: false,
    }
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseImage = this.handleCloseImage.bind(this);
    this.DownloadPDFFile = this.DownloadPDFFile.bind(this);
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
  }

  async componentDidMount() {
    this.getPDFDataF();
  }

  refreshList() {
    if (this.props.role === 'Player') {
      this.setState({ SelectedUserName: this.props.username })
    } else {

      if (this.props.role !== 'Da' && this.props.role !== 'Agent') {
        this.DaAgentList();
      }
      if (this.props.role !== 'Agent') {
        this.AgentList(this.state.filterDaA);
      }
      this.getUserLists(this.state.filterAgent);
    }


  }

  async DaAgentList() {
    let DaAgentList = await getDaAgentListF(this.props.username, this.props.token, moment(this.props.date).format('YYYY-MM-DD'))
    DaAgentList = DaAgentList.sort((a, b) => (a.Name < b.Name) ? -1 : (a.Name > b.Name) ? 1 : 0);
    DaAgentList = DaAgentList.map((item) => ({
      value: item.ID,
      label: `${item.Name}${(item.TotalOrder !== 0) ? ` (${item.TotalOrder} 单)` : ''}`,
    }))
    this.setState({ userListDA: DaAgentList })
  }

  async AgentList(UserID) {
    this.setState({ AgentListDisable: true })
    let userList = await getAgentList(this.props.username, this.props.token, UserID, moment(this.props.date).format('YYYY-MM-DD'))
    userList = userList.sort((a, b) => (a.Name < b.Name) ? -1 : (a.Name > b.Name) ? 1 : 0);
    userList = userList.map((item) => ({
      value: item.ID,
      label: `${item.Name}${(item.TotalOrder !== 0) ? ` (${item.TotalOrder} 单)` : ''}`,
    }))
    if (userList) {
      this.setState({ userList, AgentListDisable: false })
    }
  }

  async getUserLists(UserID) {
    this.setState({ PlayerListDisable: true })
    let allPlayerList = await getAllPlayerListF(this.props.username, this.props.token, UserID, moment(this.props.date).format('YYYY-MM-DD'))
    allPlayerList = allPlayerList.sort((a, b) => (a.Name < b.Name) ? -1 : (a.Name > b.Name) ? 1 : 0);
    allPlayerList = allPlayerList.map((item) => ({
      value: item.ID,
      label: `${item.Name}${(item.TotalOrder !== 0) ? ` (${item.TotalOrder} 单)` : ''}`,
    }))
    if (allPlayerList) {
      this.setState({ userListPlayer: allPlayerList, PlayerListDisable: false});
    }
  }

  async MultiPrintDFFile() {
    this.setState({ Disabled: true })
    let SelectedArray = []
    for (let i = 0; i < this.state.pdfData.length; i += 1) {
      if (this.state.pdfData[i].selected) {
        SelectedArray.push({Img: this.state.pdfData[i].Url, Username: this.state.pdfData[i].Username})
      }
    }

    if(SelectedArray.length === 0) {
      this.openNotification(`${language[this.props.currentLanguage].PleaseSelectedData}`)
      this.setState({ notificationType: 'error', Disabled: false })
    } else {
      ExportPdf(SelectedArray, this.props.username)
      this.openNotification(`${language[this.props.currentLanguage].Success}`)
      this.setState({ 
        notificationType: 'success',
        Disabled: false,
        pdfData: this.state.pdfData.map((item) => ({
          ...item,
          selected: false
        })),
        allSelected: false
      })
    }
  }

  async MultiDeletePDFFile() {
    this.setState({ highlighted: '' })
    let DeleteArray = []
    for (let i = 0; i < this.state.pdfData.length; i += 1) {
      if (this.state.pdfData[i].selected) {
        DeleteArray.push(this.state.pdfData[i].ID)
      }
    }
    if(DeleteArray.length === 0) {
      this.openNotification(`No data`)
      this.setState({ notificationType: 'error' })
    } else {
      const r = (window.confirm(`确定要删除选择上传吗?`));
      if (r === true) {
        const DeleteSelected = await deleteScanFNC(DeleteArray, this.props.username, this.props.token)
        if(DeleteSelected.message === 'Delete Successful') {
          this.openNotification(`成功删除选择data`)
          this.setState({ notificationType: 'success' })
          this.getPDFDataF();
        }
      } else {
        return null;
      }
    }
  }

  async DeletePDFFile(selected) {
    let SelectedArray = [selected.ID]
    const r = (window.confirm(`确定要删除选择上传吗?`));
    if (r === true) {
      const DeleteSelected = await deleteScanFNC(SelectedArray, this.props.username, this.props.token)
      if(DeleteSelected.message === 'Delete Successful') {
        this.openNotification(`成功删除选择data`)
        this.setState({ notificationType: 'success' })
        this.getPDFDataF();
      }
    } else {
      return null;
    }
    this.setState({ highlighted: '' })
  }

  async getPDFDataF() {
    this.refreshList()
    let FilterSearch = ''
    if (this.state.filterUser !== '') {
      FilterSearch = this.state.filterUser
    } else if (this.state.filterUser === '' && this.state.filterAgent !== '') {
      FilterSearch = this.state.filterAgent
    } else if (this.state.filterUser === '' && this.state.filterAgent === '' && this.state.filterDaA !== '') {
      FilterSearch = this.state.filterDaA
    }
    if (this.props.role === 'Player') {
      FilterSearch = this.props.userID
    }
    let pdfData = await getPDFData(this.props.username, this.props.token, moment(this.props.date).format('YYYY-MM-DD'), [{ID: FilterSearch}])

    if (pdfData.message === 'Retrieve Successful') {
      this.setState({ 
        pdfData: pdfData.Data.map((item) => ({
          ...item,
          selected: false,
        })),
        allSelected: false,
        DataQuantity: pdfData.Data.length,
      });
      localStorage.setItem('UrlImages', JSON.stringify(this.state.pdfData));
    } else if(pdfData.message === 'No Data') {
      this.setState({ pdfData: [], allSelected: false, DataQuantity: 0 });
    }
    this.setState({ highlighted: '' })
  }

  changeSelected(index, value) {
    const copyPDFData = JSON.parse(JSON.stringify(this.state.pdfData))
    copyPDFData[index].selected = value
    this.setState({pdfData: copyPDFData, SelectedFrom: ''})
  }

  changeAllSelected(value) {
    this.setState({
      pdfData: this.state.pdfData.map((item) => ({
        ...item,
        selected: value
      })),
      allSelected: value
    })
  }

  async DisplayPDFFile(DataDetials) {
    window.open(DataDetials, '_blank', 'noopener,noreferrer');
  }

  async DownloadPDFFile(DataDetials, index) {
    const link = DataDetials.Url.replace('https', 'http');
    // .replace('https://scan-prd.s3.ap-southeast-1.amazonaws.com', 'https://d1egw2l1nerbt8.cloudfront.net').replace('https://scan-prd.s3.amazonaws.com', 'https://d1egw2l1nerbt8.cloudfront.net')
    fetch(link)
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = link.replace(/^.*[\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
    this.setState({ highlighted: index })
    // const base64 = await getBase64API(this.props.username, this.props.token, DataDetials.Url)
    // const blob = this.b64toBlob(base64.base64, 'png', 512);
    // let blobUrl = window.URL.createObjectURL(blob);
    // let a = document.createElement('a');
    // a.download = DataDetials.Url.replace(/^.*[\\/]/, '');
    // a.href = blobUrl;
    // document.body.appendChild(a);
    // a.click();
    // a.remove();
    // this.setState({ highlighted: index })
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  async MultiDownloadPDFFile() {
    for (let i = 0; i < this.state.pdfData.length; i += 1) {
      if (this.state.pdfData[i].selected) {
        const base64 = await getBase64API(this.props.username, this.props.token, this.state.pdfData[i].Url)
        const blob = this.b64toBlob(base64.base64, 'png', 512);
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = this.state.pdfData[i].Url.replace(/^.*[\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    }
  }

  async openMoveFileModal(item) {
    const allPlayerList = await getPlayerList(this.props.username, this.props.token)
    if (item && item.ID) {
      this.setState({ moveFileID: [item.ID], playerList: allPlayerList, moveFileModal: true })
    } else {
      const moveFileID = this.state.pdfData.filter((item) => item.selected === true).map((item) => item.ID)
      this.setState({ moveFileID, playerList: allPlayerList, moveFileModal: true })
    }
  }

  async moveFileF() {
    const moveFileResult = await setMoveFile(this.props.username, this.props.token, this.state.moveFileID, this.state.moveFileUser)
    if (moveFileResult.message === 'Success Updated') {
      this.setState({ moveFileModal: false, notificationType: 'success'})
      this.openNotification(`SuccessfulMove`)
      this.getPDFDataF()
    }
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 3000);
  };

  SelectedFrom(Selected, index) {
    const copyPDFData = JSON.parse(JSON.stringify(this.state.pdfData))
    copyPDFData[index].selected = !Selected.selected
    if (copyPDFData[index].selected === true) {
      this.setState({ SelectedFrom: index })
    } else {
      this.setState({ SelectedFrom: '' })
    }
    this.setState({pdfData: copyPDFData})
  }

  Selectedto(Selected, index) {
    const copyPDFData = JSON.parse(JSON.stringify(this.state.pdfData))
    copyPDFData[index].selected = !Selected.selected

    if(this.state.SelectedFrom !== '') {
      for(var i = this.state.SelectedFrom + 1; i < index; i++) {
        copyPDFData[i].selected = true
      }
    }
    this.setState({pdfData: copyPDFData})
  }

  handleClose() {
    this.setState({
      UploadModal: false,
      Base64file: [],
      file: [],
    })
    this.fileArray = []
    this.fileObj = []
    this.Base64 = []
  }

  uploadMultipleFiles(e) {
    this.fileArray = []
    this.fileObj = []
    this.Base64 = []
    if (e.target.files.length > 0) {
      this.fileObj.push(e.target.files)
      for (let i = 0; i < this.fileObj[0].length; i++) {
        this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
        getBase64(this.fileObj[0][i]).then(
          Base64data => this.Base64.push(Base64data)
        )
      }
      this.setState({ file: this.fileArray, Base64file: this.Base64 })
    }
  }

  async uploadFiles(e) {
    this.setState({ UploadLoading: true })
    e.preventDefault()
    if (this.state.file.length === 0) {
      alert('No Selected files')
      this.setState({ UploadLoading: false })
    } else {
      this.setState({ UploadLoading: true })
      const file = []
      for (let i = 0; i < this.state.Base64file.length; i += 1) {
        if (this.state.Base64file[i].startsWith('data:image/jpeg;base64,')) {
          file.push({
            filetype: 'jpeg',
            base64: this.state.Base64file[i].substring(23, this.state.Base64file[i].length)
          })
        } else if (this.state.Base64file[i].startsWith('data:image/png;base64,')) {
          file.push({
            filetype: 'png',
            base64: this.state.Base64file[i].substring(22, this.state.Base64file[i].length)
          })
        } else if (this.state.Base64file[i].startsWith('data:application/pdf;base64,')) {
          file.push({
            filetype: 'pdf',
            base64: this.state.Base64file[i].substring(28, this.state.Base64file[i].length)
          })
        }
      }
      let multiUploadResult = ''
      if(this.props.role === 'Player') {
        multiUploadResult = await multiUpload(this.props.username, this.props.token, file, null, this.state.ConvertPDF)
      } else {
        multiUploadResult = await multiUpload(this.props.username, this.props.token, file, this.state.SelectedUserName, this.state.ConvertPDF)
      }
      if (multiUploadResult.message === 'Added Successful') {
        this.setState({ UploadLoading: false })
        this.handleClose();
        this.getPDFDataF();
        this.setState({ notificationType: 'success'})
        this.openNotification(`添加成功`)
      } else if (multiUploadResult.error) {
        this.getPDFDataF();
        this.openNotification(`${multiUploadResult.error}`)
        this.setState({ notificationType: 'error', UploadLoading: false })
      } else {
        this.setState({ UploadLoading: false })
      }
    }
  }

  UploadModals() {
    const SelectedUserName = this.state.SelectedUserName

    if (SelectedUserName === '') {
      this.openNotification(`请选择上传用户`)
      this.setState({ notificationType: 'error' })
    } else {
      this.setState({ UploadModal: true })
    }
  }

  handleCloseImage() {
    this.setState({
      SelectedImageModal: false,
      SelectedImage: '',
    })
  }

  OpenPdf(SelectedPdf, index) {
    window.open(SelectedPdf, '_blank', 'noopener,noreferrer');
    this.setState({ highlighted: index })
  }

  render() {
    return (
      <div style={{ fontFamily: 'cursive', backgroundImage: 'linear-gradient(#D0F0F5, #E7EDFF, #F0E8FF)', minHeight: '100vh' }}>
        <Header/>
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        {/* {this.state.SelectedImageModal === true && 
        <OpenSelectedImg SelectedImageModal={this.state.SelectedImageModal} handleCloseImage={this.handleCloseImage} SelectedImage={this.state.SelectedImage} DownloadPDFFile={this.DownloadPDFFile}/>
        } */}
        {/* <ImgsViewer
          imgs={[
            { src: this.state.SelectedImage.Url },
          ]}
          isOpen={this.state.ShowImage}
          onClose={() => this.setState({ ShowImage: false })}
        /> */}
        <Modal centered show={this.state.UploadModal} size="lg" onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {language[this.props.currentLanguage].Upload}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>{language[this.props.currentLanguage].Username}</div>
              <Form.Control style={{ width: 150}} value={this.state.SelectedUserName} disabled={true} />
            </div>
            {this.state.UploadLoading === false && 
            <div style={{ marginTop: 5, marginBottom: 10}}>
              <form>
                <div className="dcc form-group multi-preview" style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 10 }}>
                  {(this.fileArray || []).map((url, ind) => (
                    <div key={ind}>
                      {Object.values(this.fileObj[0])[ind].type.includes('image') ?
                        <img style={{ width: 200, height: 200, padding: 1, cursor: 'pointer' }} src={url} alt="images" onClick={() => this.DisplayPDFFile(url, 'review')}/>
                        : 
                        <div className='dcc' style={{ width: 200, height: 200, flexDirection: 'column', cursor: 'pointer' }} onClick={() => this.DisplayPDFFile(url, 'review')}>
                          <img style={{ width: 80, height: 80 }} src={PDF1} alt="logo" /><div>{`PDF File${ind+1}`}</div>
                        </div>}
                    </div>
                  ))}
                </div>
                <div className="form-group">
                  <input type="file" accept=".pdf,.jpg,.png,.jpeg" className="form-control" onChange={this.uploadMultipleFiles} multiple />
                </div>
                <div class="wrap" className='UserListButton' style={{  marginTop: 10 }}>
                  <button class="buttonAdd" onClick={this.uploadFiles}>{language[this.props.currentLanguage].UploadFiles}</button>
                </div>
              </form >
            </div>
            }
            {this.state.UploadLoading === true && 
            <>
              <div className='dcc'><img src={loading} alt="Logo" className="dcc loadingimages"/></div>
            </>}
            <div style={{ marginRight: 5 }}>
              <button class="buttonDelete" onClick={() => this.handleClose()}>{language[this.props.currentLanguage].Close}</button>
            </div>
          </Modal.Body>
        </Modal>
        <div className='dcc SearchDataHeaderView'>
          <div className='SearchDataHeaderViewCon'>
            <h2 className='TitleSize'>{`游览`}</h2>
            {this.props.role !== 'Player' && 
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {(this.props.role !== 'Da' && this.props.role !== 'Agent') && 
              <div className='dcc' style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].DaAgent}: </div>
                <Select styles={{
                  container: provided => ({
                    ...provided,
                    width: 150
                  }),
                }} options={this.state.userListDA} onChange={(e) => {
                  this.setState({ filterAgent: '', filterUser: '', filterDaA: e.value }, () => {
                    if (this.state.filterDaA !== '') {
                      this.AgentList(this.state.filterDaA);
                      this.getUserLists(this.state.filterDaA);
                    } else {
                      this.AgentList('');
                      this.getUserLists('');
                    }
                  })
                }}/>
                {/* <Form.Select style={{ width: 100}} onChange={(e) => {
                  this.setState({ filterAgent: '', filterUser: '', filterDaA: e.target.value }, () => {
                    if (this.state.filterDaA !== '') {
                      this.AgentList(this.state.filterDaA);
                      this.getUserLists(this.state.filterDaA);
                    } else {
                      this.AgentList('');
                      this.getUserLists('');
                    }
                  })
                }}>
                  <option value=''></option>
                  {this.state.userListDA.map((item, index) => 
                    <option key={index} value={item.ID}>{item.Name}{(item.TotalOrder !== 0) ? ` (${item.TotalOrder} 单)` : ''}</option>
                  )}
                </Form.Select> */}
              </div>}
              {this.props.role !== 'Agent' && 
              <div className='dcc' style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Agent}: </div>
                <Select styles={{
                  container: provided => ({
                    ...provided,
                    width: 150
                  }),
                }} options={this.state.userList} onChange={(e) => {
                  this.setState({ filterAgent: e.value, filterUser: ''}, () => {
                    if (this.state.filterAgent !== '') {
                      this.getUserLists(this.state.filterAgent);
                    } else {
                      if (this.state.filterDaA !== '') {
                        this.getUserLists(this.state.filterDaA);
                      } else {
                        this.getUserLists('');
                      }
                    }
                  })
                }}/>
                {/* <Form.Select disabled={this.state.AgentListDisable} style={{ width: 100}} onChange={(e) => {
                  this.setState({ filterAgent: e.target.value, filterUser: ''}, () => {
                    if (this.state.filterAgent !== '') {
                      this.getUserLists(this.state.filterAgent);
                    } else {
                      if (this.state.filterDaA !== '') {
                        this.getUserLists(this.state.filterDaA);
                      } else {
                        this.getUserLists('');
                      }
                    }
                  })
                }}>
                  <option value=''></option>
                  {this.state.userList.map((item, index) => 
                    <option key={index} value={item.ID}>{item.Name}{(item.TotalOrder !== 0) ? ` (${item.TotalOrder} 单)` : ''}</option>
                  )}
                </Form.Select> */}
              </div>}
              <div className='dcc' style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].User}: </div>
                <Select styles={{
                  container: provided => ({
                    ...provided,
                    width: 150
                  }),
                }} options={this.state.userListPlayer} onChange={(e) => {
                  let GetUsernames = ''
                  if (e.value !== '') {
                    let GetUsername = e.label
                    GetUsernames = GetUsername.label
                  } else {
                    GetUsernames = ''
                  }
                  this.setState({ SelectedUserName: GetUsernames, filterUser: e.value}, () => {
                    this.getPDFDataF();
                  })
                }}></Select>
                {/* <Form.Select disabled={this.state.PlayerListDisable} style={{ width: 100}} onChange={(e) => {
                  let GetUsernames = ''
                  if (e.target.value !== '') {
                    let GetUsername = this.state.userListPlayer.filter((value) => value.ID.toString() === e.target.value);
                    GetUsernames = GetUsername[0].Name
                  } else {
                    GetUsernames = ''
                  }
                  this.setState({ SelectedUserName: GetUsernames, filterUser: e.target.value}, () => {
                    this.getPDFDataF();
                  })
                }}>
                  <option value=''></option>
                  {this.state.userListPlayer.map((item, index) => 
                    <option key={index} value={item.ID}>{item.Name}{(item.TotalOrder !== 0) ? ` (${item.TotalOrder} 单)` : ''}</option>
                  )}
                </Form.Select> */}
              </div>
            </div>}
            {this.props.role !== 'Player' && 
            <div className='btnMove b1 MoveButtonSize' onClick={() => this.getPDFDataF()} style={{ marginRight: 10, marginLeft: 10 }}>{language[this.props.currentLanguage].Search}</div>
            }
            <div className='TotalFontSize' style={{ fontWeight: 'bold', paddingLeft: 10 }}>{`${language[this.props.currentLanguage].Total} : ${this.state.DataQuantity}`}</div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {(this.props.role === 'Agent' || this.props.role === 'Da' || this.props.role === 'Player') && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {this.props.AbleConvertPDF !== '0' && <div className='dcc' style={{ display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                <div style={{ fontWeight: 'bold', fontSize: 20, marginRight: 10 }}>{'转换'}</div>
                <Form.Check 
                  type="switch"
                  id="custom-switch"
                  label=""
                  className="middleSwitch"
                  checked={this.state.ConvertPDF}
                  onChange={() => this.setState({ ConvertPDF: !this.state.ConvertPDF})}
                  style={{  }}
                />
              </div>}
              <button class="buttonAdd" style={{ marginRight: 10 }} onClick={() => this.UploadModals()}>{language[this.props.currentLanguage].Upload}</button>
            </div>
            }
            {this.props.role === 'Admin' && 
            <div className='btnMove bDelete' onClick={() => this.MultiDeletePDFFile()} style={{ marginRight: 10 }}>{language[this.props.currentLanguage].MultiDelete}</div>
            }
            <div className='btnMove b1 MoveButtonSize' onClick={() => this.MultiDownloadPDFFile()} style={{ marginRight: 10 }}>{language[this.props.currentLanguage].MultiDownload}</div>
            <div className='btnMove b1 MoveButtonSize' onClick={() => this.MultiPrintDFFile()} style={{ marginRight: 10 }}>{language[this.props.currentLanguage].MultiPrint}</div>
            {this.props.role === 'Admin' && 
            <div className='btnMove b1 ' onClick={() => this.openMoveFileModal()} style={{ marginRight: 10 }}>{language[this.props.currentLanguage].MultiMove}</div>
            }
            <img src={gridview} alt={'gridview'} style={{ borderRadius: 10, cursor: 'pointer', padding: 10, width: 40, height: 40, backgroundColor: !this.state.gridView ? '#fff': '#0d6efd'}} onClick={() => this.setState({ gridView: true })}/>
            <img src={listview} alt={'listview'} style={{ borderRadius: 10, cursor: 'pointer', padding: 10, width: 40, height: 40, backgroundColor: this.state.gridView ? '#fff': '#0d6efd'}} onClick={() => this.setState({ gridView: false })}/>
          </div>
        </div>
        <Modal centered show={this.state.moveFileModal} onHide={()=> this.setState({ moveFileModal: false})}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {'移动照片'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>{language[this.props.currentLanguage].MoveTo}</div>
              <Form.Select style={{ width: 100}} onChange={(e) => {
                this.setState({ moveFileUser: e.target.value})
              }}>
                <option value=''></option>
                {this.state.playerList.map((item, index) => 
                  <option key={index} value={item.ID}>{item.Username}</option>
                )}
              </Form.Select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => this.setState({ moveFileModal: false })}>{language[this.props.currentLanguage].Close}</Button>
            <Button variant="success" onClick={() => this.moveFileF()}>{language[this.props.currentLanguage].Move}</Button>
          </Modal.Footer>
        </Modal>
        {this.state.gridView 
          ? <div style={{ display: 'flex', width: '100vw', flexWrap: 'wrap'}}>
            {this.state.pdfData.length > 0 ?
              this.state.pdfData.map((item, index) => 
                <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #000', padding: 10, alignItems: 'center', margin: 5, backgroundColor: this.state.highlighted === index ? '#ADD8E6' : '#fff'}}>
                  {item.FileName.substring(item.FileName.length - 3,item.FileName.length) === 'pdf' ? (
                    <div onClick={() => this.OpenPdf(item.Url, index)}>
                      <img  className="buttonshadow" style={{ maxWidth: 100, maxHeight: 100, cursor: 'pointer' }} src={PDF1} alt="logo" />
                    </div>
                  ) : (
                    <a href={`OpenWindowReceipt?ID=${item.ID}&DisplayImg=${item.Url}&SelectedIndex=${index}`} target="_blank" rel="noreferrer"><img src={item.Url} alt={item.FileName} className="buttonshadow" style={{ maxWidth: 100, maxHeight: 100, cursor: 'pointer' }} onClick={() => this.setState({ highlighted: index })}/></a>
                  )}
                  <div>{moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                  <div>{item.Username}</div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Button style={{ minWidth: 80 }} onClick={() => this.DownloadPDFFile(item, index)} variant="primary">{language[this.props.currentLanguage].Download}</Button>
                    <Form.Check style={{ marginLeft: 10 }} type="checkbox" checked={item.selected} onChange={(e) => this.changeSelected(index, e.target.checked)}/>
                  </div>
                </div>
              )
              :
              <div style={{ backgroundColor: 'lightgray', fontSize: 20}}>{language[this.props.currentLanguage].NoData}</div>}
          </div>
          : <Table bordered hover responsive style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)', width: '98%' }} className='BoxShadow'>
            <thead>
              <tr>
                <th>No</th>
                <th style={{ minWidth: 120 }}>{language[this.props.currentLanguage].Photo}</th>
                <th style={{ minWidth: 180 }}>{language[this.props.currentLanguage].Name}</th>
                <th style={{ minWidth: 120 }}>{language[this.props.currentLanguage].Date}</th>
                <th style={{ minWidth: 60 }}>{language[this.props.currentLanguage].User}</th>
                <th style={{ minWidth: 60 }}>{language[this.props.currentLanguage].Status}</th>
                <th style={{ minWidth: 100 }}>
                  <div style={{ display: 'flex' }}>
                    {language[this.props.currentLanguage].Action}
                    <Form.Check style={{ marginLeft: 10 }} type="checkbox" checked={this.state.allSelected} onChange={(e) => this.changeAllSelected(e.target.checked)}/>
                  </div>
                </th>
              </tr>
            </thead>
            {this.state.pdfData.length > 0 ?
              <tbody>
                {this.state.pdfData.map((item, index) => <tr key={index} style={{ backgroundColor: this.state.highlighted === index ? '#ADD8E6' : '#fff'}}>
                  <td className='ListFontSize'>{index + 1}</td>
                  <td>
                    {item.FileName.substring(item.FileName.length - 3,item.FileName.length) === 'pdf' ? (
                      <div onClick={() => this.OpenPdf(item.Url, index)}>
                        <img  className="buttonshadow" style={{ maxWidth: 100, maxHeight: 100, cursor: 'pointer' }} src={PDF1} alt="logo" />
                      </div>
                    ) : (
                      <a href={`OpenWindowReceipt?ID=${item.ID}&DisplayImg=${item.Url}&SelectedIndex=${index}`} target="_blank" rel="noreferrer"><img src={item.Url} alt={item.FileName} className="buttonshadow" style={{ maxWidth: 100, maxHeight: 100, cursor: 'pointer' }} onClick={() => this.setState({ highlighted: index })}/></a>
                    )}
                  </td>
                  <td className='ListFontSize'>{item.FileName}</td>
                  <td className='ListFontSize'>{moment(item.Date).format('YYYY-MM-DD')}</td>
                  <td className='ListFontSize'>{item.Username}</td>
                  <td className='ListFontSize' style={{ color: (item.ReadBy) ? '#0EA205' : 'red', fontWeight: 'bold' }}>{item.ReadBy ? `${language[this.props.currentLanguage].Readed}` : `${language[this.props.currentLanguage].NotRead}`}</td>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className='btnMove b1' style={{ marginRight: 10 }}>
                        {item.FileName.substring(item.FileName.length - 3,item.FileName.length) === 'pdf' ? (
                          <div onClick={() => this.OpenPdf(item.Url, index)} style={{ color: 'white' }} rel="noreferrer">{language[this.props.currentLanguage].Open}</div>
                        ) : (
                          <a href={`OpenWindowReceipt?ID=${item.ID}&DisplayImg=${item.Url}&SelectedIndex=${index}`} onClick={() => this.setState({ highlighted: index })} target="_blank" style={{ color: 'white' }} rel="noreferrer">{language[this.props.currentLanguage].Open}</a>
                        )}
                      </div>
                      <div className='btnMove b1' onClick={() => this.DownloadPDFFile(item, index)} style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Download}</div>
                      {this.props.role === 'Admin' &&
                        <div className='btnMove bDelete' onClick={() => this.DeletePDFFile(item)} style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Delete}</div> 
                      }
                      {this.props.role === 'Admin' && 
                        <div className='btnMove b1' onClick={() => this.openMoveFileModal(item)}>{language[this.props.currentLanguage].Move}</div>
                      }
                      <Form.Check style={{ marginLeft: 10 }} type="checkbox" checked={item.selected} onChange={(e) => this.changeSelected(index, e.target.checked)}/>
                      <Button style={{ minWidth: 70, marginRight: 10, marginLeft: 10 }} onClick={() => this.SelectedFrom(item, index)} variant="success">{language[this.props.currentLanguage].from}</Button>
                      <Button style={{ minWidth: 70 }} onClick={() => this.Selectedto(item, index)} variant="success">{language[this.props.currentLanguage].to}</Button>
                    </div>
                  </td>
                </tr>)}
              </tbody>
              :
              <tbody>
                <tr style={{ backgroundColor: 'lightgray'}}>
                  <td colSpan="9" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
                </tr>
              </tbody>}
          </Table>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
    date: state.date,
    userID: state.userID,
    AbleConvertPDF: state.userID,
  };
}

export default connect(mapStateToProps, null)(ScanList);
