import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form'
import ShowPass from '../image/ShowPass.png';
import HidePass from '../image/HidePass.png';
import Button from 'react-bootstrap/Button'
import { getLogin } from '../Api'
import Container from 'react-bootstrap/Container'

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: '',
      Password: '',
      loading: false,
      DisplayPass: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.UsernameInput = React.createRef();
    this.PasswordInput = React.createRef();
  }

  componentDidMount() {
    localStorage.removeItem('UrlImages');
  }

  async handleSubmit() {
    this.setState({ loading: true });
    if (this.state.Username === '') {
      alert(`请输入用户名`);
      this.props.setLoginStatus('', '', '', '', '', '');
      this.setState({ loading: false})
    } else if (this.state.Password === '') {
      alert(`请输入密码`);
      this.setState({ loading: false})
      this.props.setLoginStatus('', '', '', '', '', '');
    }
    else {
      const LoginStatus = await getLogin(
        this.state.Username,
        this.state.Password,
        'website',
      );
      if (LoginStatus.error && LoginStatus.error === 'Your account and/or password is incorrect, please try again') {
        alert('账号/密码错误');
        this.setState({ loading: false })
        this.props.setLoginStatus('', '', '', '', '', '');
        this.PasswordInput.focus();
        this.props.setDate('')
      } else if (LoginStatus.message && LoginStatus.message === 'Login Success') {
        this.props.setDate(LoginStatus.userData.currentDate)
        this.props.setLoginStatus(
          LoginStatus.userData.Username,
          LoginStatus.userData.Token,
          LoginStatus.userData.Role,
          LoginStatus.userData.ID,
          LoginStatus.userData.AbleConvertPDF,
          LoginStatus.userData.AgentSummaryDownline,
        );
        sessionStorage.setItem("scandate", LoginStatus.userData.currentDate);
        sessionStorage.setItem("scantoken", LoginStatus.userData.Token);
        sessionStorage.setItem("scanusername", LoginStatus.userData.Username);
        sessionStorage.setItem("scanrole", LoginStatus.userData.Role);
        sessionStorage.setItem("scanuserID", LoginStatus.userData.ID);
        sessionStorage.setItem("scanAbleConvertPDF", LoginStatus.userData.AbleConvertPDF);
        sessionStorage.setItem("SummaryDownline", LoginStatus.userData.AgentSummaryDownline);
        this.setState({ loading: false })
        if (LoginStatus.userData.Role === 'Agent' || LoginStatus.userData.Role === 'Player' || LoginStatus.userData.Role === 'Da') {
          this.props.history.push('/HomepageUSers')
        } else {
          this.props.history.push('/Work')
        }
      }
    }
  }
  async onKeyEnter(e) {
    if (e.charCode === 13) {
      this.handleSubmit()
    }
  }

  handleChange(value, field) {
    this.setState({ [field]: value });
  }

  render() {
    return (
      <Container className='dcc' fluid style={{ height: '100vh', backgroundImage: 'linear-gradient(#D0F0F5, #E7EDFF, #E3D5FF)', display: 'flex', flexDirection: 'column', fontFamily: 'cursive'}}>
        <div style={{ marginBottom: 10, fontWeight: 'bold', fontSize: '35px' }}>Scan</div>
        <div className='dcc' style={{flexDirection: 'column', width: '100%'}}>
          <div className='dcc LoginContainer' style={{ backgroundColor: 'white'}}>
            <div className='dcc USernameLogin'>
              <div className='ChangePassName'>用户名</div>
              <div className='ChangePassInput'>
                <Form.Control 
                  onKeyPress={(e) => this.onKeyEnter(e)}
                  value={this.state.Username}
                  type='text' style={{ marginRight: 10, width: '100%', backgroundColor: 'white' }}
                  onChange={(e) => this.setState({ Username: e.target.value })}
                />
              </div>
            </div>
            <div className='dcc ChangePAsswordTextView'>
              <div className='ChangePassName'>密码</div>
              <div className='ChangePassInput'>
                <Form.Control 
                  onKeyPress={(e) => this.onKeyEnter(e)}
                  ref={(input) => { this.PasswordInput = input; }}
                  value={this.state.Password}
                  type={(this.state.DisplayPass === true) ? 'text' : 'password'} style={{ marginRight: 10, width: '100%', backgroundColor: 'white' }}
                  onChange={(e) => this.setState({ Password: e.target.value })}
                />
                <div style={{ width: '5%', cursor: 'pointer' }} onClick={() => this.setState({ DisplayPass: !this.state.DisplayPass })}>
                  {this.state.DisplayPass === true ? (
                    <img src={ShowPass} alt="Logo" className='PasswordEye' />
                  ) : (
                    <img src={HidePass} alt="Logo" className='PasswordEye' />
                  )}
                </div>
              </div>
            </div>
            <button class="pushable" style={{ minWidth: 150, padding: 2 }} onClick={() => this.handleSubmit()}>
              <span class="front" style={{ fontWeight: 'bold' }}>
                登入
              </span>
            </button>
          </div>
        </div>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, role, userID, AbleConvertPDF, SummaryDownline) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          role: role,
          userID: userID,
          AbleConvertPDF: AbleConvertPDF,
          SummaryDownline: SummaryDownline,
        },
      });
    },
    setDate: (date) => {
      dispatch({
        type: 'SET_DATE',
        payload: {
          date,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Login);
  