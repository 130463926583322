import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Reducers from './reducers/reducer';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import AppFunction from './AppFunction';
import './Table.css';

function App() {
  const middlewares = applyMiddleware(thunk);

  const store = createStore(
    Reducers, {
      username: sessionStorage.getItem('scanusername') || '',
      role: sessionStorage.getItem('scanrole') || '',
      token: sessionStorage.getItem('scantoken') || '',
      userID: sessionStorage.getItem('scanuserID') || '',
      date: sessionStorage.getItem('scandate') || '',
      UserSelectedList: sessionStorage.getItem('UserSelectedList') || '',
      currentLanguage: sessionStorage.getItem('currentLanguage') || 'cn',
      AbleConvertPDF: sessionStorage.getItem('scanAbleConvertPDF') || '0',
      SummaryDownline: sessionStorage.getItem('SummaryDownline') || '',
    },
    middlewares,
  )
  return (
    <Provider store={store}>
      <AppFunction/>
      {/* <BrowserRouter style={{ position: 'relative' }}>
        <Header/>
        <Switch>
          <Route exact path="/" component={Login}/>
          <Route exact path="/ScanList" component={ScanList} />
          <Route exact path="/UserList" component={UserList} />
          <Route exact path="/AdminList" component={AdminList} />
          <Route exact path="/UserHomePage" component={UserHomePage} />
          <Route exact path="/Work" component={Work} />
          <Route exact path="/Configuration" component={Configuration} />
        </Switch>
      </BrowserRouter> */}
    </Provider>
  );
}
export default App;
