import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import { Container, Form, Button, Table, Modal,Alert } from 'react-bootstrap';
import loading from '../image/loadings.gif';
import PDF1 from '../image/PDF2.png';
// import ImageUploadPreviewComponent from '../Component/ImageUploadPreviewComponent';
import { getBase64 } from '../Component/getBase64';
import { getPDFData, multiUpload, readby } from '../Api';
import Header from '../Component/Header';
import moment from 'moment-timezone';
import gridview from '../image/gridview.png';
import listview from '../image/listview.png';
var timer

class UserHomePage extends React.Component {

  fileObj = [];
  fileArray = [];
  Base64 = []

  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      userObj: [],
      filterUser: '',
      pdfData: [],
      UploadModal: false,
      SelectedFiles: '',
      Onefile: '',
      file: [],
      Base64file: [],
      gridView: false,
      UploadLoading: false,
      notificationMessage: '',
      showNotification: false,
      notificationType: 'success',
    }
    this.handleClose = this.handleClose.bind(this);
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
  }

  async componentDidMount() {
    this.getPDFDataF();
  }

  async getPDFDataF() {
    const pdfData = await getPDFData(this.props.username, this.props.token, moment(this.props.date).format('YYYY-MM-DD'), [{ID: this.props.userID}])
    if (pdfData.message === 'Retrieve Successful') {
      this.setState({ pdfData: pdfData.Data });
    } else if(pdfData.message === 'No Data') {
      this.setState({ pdfData: [] });
    } else {
      // show no message notification
    }
  }

  async DisplayPDFFile(DataDetials, type) {
    window.open(DataDetials.Url, '_blank', 'noopener,noreferrer');
  }

  handleClose() {
    this.setState({
      UploadModal: false,
      SelectedFiles: '',
      Base64file: [],
      file: [],
    })
    this.fileArray = []
    this.fileObj = []
    this.Base64 = []
  }

  uploadMultipleFiles(e) {
    this.fileArray = []
    this.fileObj = []
    this.Base64 = []
    if (e.target.files.length > 0) {
      this.fileObj.push(e.target.files)
      for (let i = 0; i < this.fileObj[0].length; i++) {
        this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
        getBase64(this.fileObj[0][i]).then(
          Base64data => this.Base64.push(Base64data)
        )
      }
      this.setState({ file: this.fileArray, Base64file: this.Base64 })
    }
  }
 
  async uploadFiles(e) {
    this.setState({ UploadLoading: true })
    e.preventDefault()
    if (this.state.file.length === 0) {
      alert('No Selected files')
      this.setState({ UploadLoading: false })
    } else {
      this.setState({ UploadLoading: true })
      const file = []
      for (let i = 0; i < this.state.Base64file.length; i += 1) {
        if (this.state.Base64file[i].startsWith('data:image/jpeg;base64,')) {
          file.push({
            filetype: 'jpeg',
            base64: this.state.Base64file[i].substring(23, this.state.Base64file[i].length)
          })
        } else if (this.state.Base64file[i].startsWith('data:image/png;base64,')) {
          file.push({
            filetype: 'png',
            base64: this.state.Base64file[i].substring(22, this.state.Base64file[i].length)
          })
        } else if (this.state.Base64file[i].startsWith('data:application/pdf;base64,')) {
          file.push({
            filetype: 'pdf',
            base64: this.state.Base64file[i].substring(28, this.state.Base64file[i].length)
          })
        }
      }
      const multiUploadResult = await multiUpload(this.props.username, this.props.token, file)
      if (multiUploadResult.message === 'Added Successful') {
        this.setState({ UploadLoading: false })
        this.handleClose();
        this.getPDFDataF();
        this.setState({notificationType: 'success'}, ()=>{
          this.openNotification(`添加成功`)
        })
      } else if (multiUploadResult.error) {
        // handle error message
        this.setState({ UploadLoading: false, notificationType: 'error' }, ()=>{
          this.openNotification(`${multiUploadResult.error}`)
        })
      } else {
        this.setState({ UploadLoading: false,})
      }
    }
  }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success'
      });
    }, 5000);
  };

  render() {
    return (
      <div fluid style={{ fontFamily: 'cursive', minHeight: '100vh', backgroundImage: 'linear-gradient(#D0F0F5, #E7EDFF, #F0E8FF)' }}>
        <Header />
        <Modal centered show={this.state.UploadModal} size="lg" onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {language[this.props.currentLanguage].Upload}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>{language[this.props.currentLanguage].Username}</div>
              <Form.Control style={{ width: 150}} value={this.props.username} disabled={true} />
            </div>
            {this.state.UploadLoading === false && 
            <div style={{ marginTop: 5, marginBottom: 10}}>
              <form>
                <div className="dcc form-group multi-preview" style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 10 }}>
                  {(this.fileArray || []).map((url, ind) => (
                    <div key={ind}>
                      {Object.values(this.fileObj[0])[ind].type.includes('image') ?
                        <img style={{ width: 200, height: 200, padding: 1 }} src={url} alt="images" />
                        : 
                        <div className='dcc' style={{ width: 200, height: 200, flexDirection: 'column', cursor: 'pointer' }} onClick={() => this.DisplayPDFFile(url, 'review')}>
                          <img style={{ width: 80, height: 80 }} src={PDF1} alt="logo" /><div>{`PDF File${ind+1}`}</div>
                        </div>}
                    </div>
                  ))}
                </div>
                <div className="form-group">
                  <input type="file" accept=".pdf,.jpg,.png,.jpeg" className="form-control" onChange={this.uploadMultipleFiles} multiple />
                </div>
                {/* <button type="button" className="btn btn-success btn-block"  onClick={this.uploadFiles} style={{ marginTop: 10, marginRight: 5 }}>{language[this.props.currentLanguage].UploadFiles}</button> */}
                <div class="wrap" className='UserListButton' style={{  marginTop: 10 }}>
                  <button class="buttonAdd" onClick={this.uploadFiles}>{language[this.props.currentLanguage].UploadFiles}</button>
                </div>
              </form >
            </div>
            }
            {this.state.UploadLoading === true && 
            <>
              <div className='dcc'><img src={loading} alt="Logo" className="dcc loadingimages"/></div>
            </>}
            {/* <Button variant="danger" onClick={() => this.handleClose()}>{language[this.props.currentLanguage].Close}</Button> */}
            <div style={{ marginRight: 5 }}>
              <button class="buttonDelete" onClick={() => this.handleClose()}>{language[this.props.currentLanguage].Close}</button>
            </div>
          </Modal.Body>
        </Modal>
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert className="dcc" style={{  width: '70%', zIndex: 99, position: 'fixed', top: 100, left: 150}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '80%' }}>
            <h2 style={{ marginRight: 10 }}>{language[this.props.currentLanguage].Upload}</h2>
          </div>
          <div className="dcc" style={{ display: 'flex' }}>
            {/* <Button style={{ minWidth: 80 }} onClick={() => this.setState({ UploadModal: true })} variant="success">{language[this.props.currentLanguage].Upload}</Button> */}
            <div class="wrap" className='UserListButton'>
              <button class="buttonAdd" onClick={() => this.setState({ UploadModal: true })}>{language[this.props.currentLanguage].Upload}</button>
            </div>
            <img src={gridview} alt={'gridview'} style={{ borderRadius: 10, cursor: 'pointer', padding: 10, width: 40, height: 40, backgroundColor: !this.state.gridView ? '#fff': '#0d6efd'}} onClick={() => this.setState({ gridView: true })}/>
            <img src={listview} alt={'listview'} style={{ borderRadius: 10, cursor: 'pointer', padding: 10, width: 40, height: 40, backgroundColor: this.state.gridView ? '#fff': '#0d6efd'}} onClick={() => this.setState({ gridView: false })}/>
          </div>
        </div>
        {this.state.gridView
          ? <div style={{ display: 'flex', width: '100vw', flexWrap: 'wrap'}}>
            {this.state.pdfData.length > 0 ?
              this.state.pdfData.map((item, index) => 
                <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #000', padding: 10, alignItems: 'center', margin: 5}}>
                  <img src={item.Url} alt={item.FileName} style={{ maxWidth: 200, maxHeight: 200}} onClick={() => this.DisplayPDFFile(item)}/>
                  <div>{moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Button style={{ minWidth: 80 }} onClick={() => this.DownloadPDFFile(item)} variant="primary">{language[this.props.currentLanguage].Download}</Button>
                    <Form.Check style={{ marginLeft: 10 }} type="checkbox" checked={item.selected} onChange={(e) => this.changeSelected(index, e.target.checked)}/>
                  </div>
                </div>
              )
              :
              <div style={{ backgroundColor: 'lightgray', fontSize: 20}}>{language[this.props.currentLanguage].NoData}</div>}
          </div> 
          : <Table striped bordered hover responsive style={{ backgroundColor: 'white'}}>
            <thead>
              <tr>
                <th>ID</th>
                <th>{language[this.props.currentLanguage].Username}</th>
                <th>{language[this.props.currentLanguage].Photo}</th>
                <th>{language[this.props.currentLanguage].Name}</th>
                <th>{language[this.props.currentLanguage].UploadTime}</th>
                <th>{language[this.props.currentLanguage].Action}</th>
              </tr>
            </thead>
            {this.state.pdfData.length > 0 ?
              <tbody>
                {this.state.pdfData.map((item, index) => <tr key={index}>
                  <td>{item.ID}</td>
                  <td>{item.Username}</td>
                  <td><img src={item.Url} alt={item.FileName} className="buttonshadow" style={{ maxWidth: 100, maxHeight: 100, cursor: 'pointer' }} onClick={() => this.DisplayPDFFile(item)}/></td>
                  <td>{item.FileName}</td>
                  <td>{moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                  <td>
                    {/* <Button style={{ minWidth: 80 }} onClick={() => this.DisplayPDFFile(item, 'view')} variant="primary">{language[this.props.currentLanguage].Open}</Button> */}
                    <div>
                      <button class="button2" onClick={() => this.DisplayPDFFile(item, 'view')}>{language[this.props.currentLanguage].Open}</button>
                    </div>
                  </td>
                </tr>)}
              </tbody>
              :
              <tbody>
                <tr style={{ backgroundColor: 'lightgray'}}>
                  <td colSpan="9" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
                </tr>
              </tbody>}
          </Table>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
    date: state.date,
    userID: state.userID,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserHomePage);
