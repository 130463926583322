const base = window.location.protocol === 'https:' ? 'https://s.7ups.site/' : 'http://118.107.233.71/';
const socketbase = window.location.protocol === 'https:' ? 'wss://s.7ups.site/' : 'ws://118.107.233.71/';
const base2 = window.location.protocol === 'https:' ? 'https://s.7ups.site/' : 'http://118.107.233.71/';
const base3 = window.location.protocol === 'https:' ? 'https://s.7ups.site/' : 'http://118.107.233.71/';

async function massageRes(res, resolve) {
  const result = await res.json()
  if (result.error && result.error === 'Invalid credentials!') {
    sessionStorage.removeItem('scantoken');
    sessionStorage.removeItem('scanusername');
    sessionStorage.removeItem('scanrole');
    sessionStorage.removeItem('UserSelectedList');
    window.location.href = '/';
    resolve()
  } else {
    resolve(result)
  }
}

function getLogin(username, userPwd, loginMethod) {
  return new Promise((resolve) => {
    fetch(`${base2}public/login`, {
      method: 'post',
      headers: {
        Authorization: 'hC2XdjZh0Wg47Wqm',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, userPwd, loginMethod }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getBase64API(username, token, url) {
  return new Promise((resolve) => {
    fetch(`${base3}public/getbase64`, {
      method: 'post',
      headers: {
        Authorization: 'hC2XdjZh0Wg47Wqm',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, url }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function setConfiguration(username, ConfigurationData, token) {
  return new Promise((resolve) => {
    fetch(`${base2}main/setConfiguration`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, ConfigurationData}),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getConfiguration(username, token, date) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getConfiguration`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, date }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getOpenDatedFNC(username, token) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getOpenDated`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getUserList(username, token, mode, searchObj) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getUserList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, mode, searchObj }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getSearchUserList(username, token, filterUsername, filterName, filterRole, upline) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getSearchUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username, 
        filterUsername,
        filterName,
        filterRole,
        upline,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getAllUserList(username, token) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getAllUserList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username, 
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}


function getServerTimeFNC(username, token) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getServerTime`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username}),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getDAList(username, token, mode, searchObj) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getDAList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, mode, searchObj }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getAgentList(username, token, UserID, DateSelected) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getAgentList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, UserID, DateSelected }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function adminZhuYeFNC(username, token, DateSelected) {
  return new Promise((resolve) => {
    fetch(`${base2}main/adminZhuYe`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, DateSelected }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getAllPlayerListF(username, token, UserID, DateSelected) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getAllPlayerList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, UserID, DateSelected }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getDaAgentListF(username, token, DateSelected) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getDaAgentList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, DateSelected }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function deleteScanFNC(scanIDArray, username, token) {
  return new Promise((resolve) => {
    fetch(`${base2}main/deleteScan`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({scanIDArray, username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getChatHistoryf(username, userID, Count, Token, Date) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getChatHistory`, {
      method: 'post',
      headers: {
        Authorization: Token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        userID,
        Count,
        Date,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function SendMessage(username, usernameOnBehalf, Message, type, sendTo, Token, ConvertPDF) {
  return new Promise(resolve => {
    fetch(`${base}main/SendMessage`, {
      method: 'post',
      headers: {
        Authorization: Token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        usernameOnBehalf,
        Message,
        type,
        sendTo,
        ConvertPDF,
      }),
    }).then(res => {
      massageRes(res, resolve);
    });
  });
}


function getPlayerList(username, token) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getPlayerList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getAdminList(username, token, mode, searchObj) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getAdminList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, mode, searchObj }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getPDFData(username, token, date, user) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getPDFData`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, date, user }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getWorkData(username, token, date, user) {
  return new Promise((resolve) => {
    fetch(`${base2}main/getWorkData`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, date, user }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function changePwdF(username, token, userID, currentPwd, newPwd) {
  return new Promise((resolve) => {
    fetch(`${base2}main/changePwd`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, userID, currentPwd, newPwd }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function createUser(username, token, createUserObject) {
  return new Promise((resolve) => {
    fetch(`${base2}main/createUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, createUserObject }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function updateUser(username, token, updateUserObject) {
  return new Promise((resolve) => {
    fetch(`${base2}main/updateUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, updateUserObject }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function deleteUser(username, deleteUserID, Password, token) {
  return new Promise((resolve) => {
    fetch(`${base2}main/deleteUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, deleteUserID, Password }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function readby(username, token, fileID, multiFileID) {
  return new Promise((resolve) => {
    fetch(`${base2}main/readby`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, fileID, multiFileID }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function multiUpload(username, token, file, usernameOnBehalf, ConvertPDF) {
  return new Promise((resolve) => {
    fetch(`${base}main/multiUpload`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, file, usernameOnBehalf, ConvertPDF }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function setMoveFile(username, token, moveFileID, moveFileUser) {
  return new Promise((resolve) => {
    fetch(`${base2}main/setMoveFile`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, moveFileID, moveFileUser }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getDownlineChatlist(username, token, Date) {
  return new Promise((resolve) => {
    fetch(`${base2}main/GetDownlineChatlist`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({username, Date}),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function readByCustomer(username, userID, chatIDArray, token) {
  return new Promise(resolve => {
    fetch(`${base2}main/readByCustomer`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        userID,
        chatIDArray,
      }),
    }).then(res => {
      massageRes(res, resolve);
    });
  });
}

function getUnreadMsg(username, userID, token) {
  return new Promise(resolve => {
    fetch(`${base2}main/getUnreadMsg`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        userID,
      }),
    }).then(res => {
      massageRes(res, resolve);
    });
  });
}

export {
  getBase64API,
  getLogin, 
  getUserList,
  getServerTimeFNC,
  getPDFData,
  getAdminList,
  createUser,
  changePwdF,
  updateUser,
  deleteScanFNC,
  deleteUser,
  readby,
  getWorkData,
  getDAList,
  multiUpload,
  getAgentList,
  adminZhuYeFNC,
  getAllPlayerListF,
  getDaAgentListF,
  setConfiguration,
  getConfiguration,
  getOpenDatedFNC,
  getPlayerList,
  setMoveFile,
  getChatHistoryf,
  getDownlineChatlist,
  SendMessage,
  readByCustomer,
  getUnreadMsg,
  socketbase,
  getSearchUserList,
  getAllUserList,
};
  