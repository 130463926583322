import React from "react";
import { connect } from 'react-redux';
import { language } from '../language';
import PDF1 from '../image/PDF2.png';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import Alert from 'react-bootstrap/Alert';

var timer

class OpenSelectedImg extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      SelectedImage: '',
      SelectedID: '',
      DisplayIndex: 0,
      AllData: [],
    }
    // this.DownloadPDFFile = this.DownloadPDFFile.bind(this);
  }

  componentDidMount () {
    this.ReturnDisplayImgs();
  }

  ReturnDisplayImgs() {
    const GetLocalData = localStorage.getItem('UrlImages');
    let searchState = this.props.location.search.substring(1, this.props.location.search.length)
    if (searchState !== '') {
      searchState = `{"${decodeURI(searchState.replace(/&/g, "\",\"").replace(/=/g,"\":\""))}"}`
      searchState = JSON.parse(searchState)
      var getDisplayArray = JSON.parse(GetLocalData).filter(function (el) {
        return el.type === 'image'
      });
      const findIndex = getDisplayArray.findIndex(object => {
        return object.ID === Number(searchState.ID);
      })
      // const getDataparse = JSON.parse(GetLocalData)[Number(searchState.SelectedIndex)].Url
      console.log('getDisplayArray[findIndex].context', getDisplayArray[findIndex].context)
      this.setState({ SelectedID: searchState.ID, DisplayIndex: findIndex, AllData: getDisplayArray, SelectedImage: getDisplayArray[findIndex].context })
    }
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 3000);
  };

  DownloadPDFFile(DataDetials) {
    const link = DataDetials.replace('https://ace-bt.s3.ap-southeast-1.amazonaws.com', 'https://d1g1vmp11h3jgx.cloudfront.net').replace('https://ace-bt.s3.amazonaws.com', 'https://d1g1vmp11h3jgx.cloudfront.net')
    fetch(link)
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = link.replace(/^.*[\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
  }

  ClickPrevious() {
    // const DisplayIndex = this.state.DisplayIndex
    // const AllData = this.state.AllData
    this.setState({ DisplayIndex: this.state.DisplayIndex - 1, SelectedImage: this.state.AllData[this.state.DisplayIndex - 1].context })
  }

  ClickNext() {
    this.setState({ DisplayIndex: this.state.DisplayIndex + 1, SelectedImage: this.state.AllData[this.state.DisplayIndex + 1].context })
  }

  async DisplayPDFFile(DataDetials) {
    window.open(DataDetials, '_blank', 'noopener,noreferrer');
  }

  render () {
    return (
      <div style={{ backgroundColor: 'linear-gradient(#D0F0F5, #E7EDFF, #E3D5FF)' }}>
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div className="dcc" style={{ width: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'linear-gradient(#D0F0F5, #E7EDFF, #E3D5FF)'}}>
          <div className="dcc" style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: 10 }}>
            {language[this.props.currentLanguage].ViewUpload}
            <button className="button2" style={{  width: '10%', marginLeft: 5 }} onClick={() => this.DownloadPDFFile(this.state.SelectedImage)}>{language[this.props.currentLanguage].Download}</button>
          </div>
          <div className="dcc" style={{ paddingBottom: 20, width: '100%', paddingTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
            {this.state.DisplayIndex !== 0 ? (
              // <div className="dcc" style={{ display: 'flex', textAlign: 'left', width: '20%', fontSize: '40px', cursor: 'pointer'}} onClick={() => this.ClickPrevious()}>{'⏮'}</div>
              <div className="dcc" style={{ display: 'flex', textAlign: 'left', width: '20%', fontSize: '40px', cursor: 'pointer'}} onClick={() => this.ClickPrevious()}>{'◀'}</div>
            ) : (
              <div className="dcc" style={{ display: 'flex', textAlign: 'left', width: '20%'}}>{''}</div>
            )}
            {this.state.AllData.length > 0 && 
            <>
              <ReactPanZoom
                image={this.state.SelectedImage}
                alt="Image alt text"
              />
              {/* {this.state.AllData[this.state.DisplayIndex].FileName.substring(this.state.AllData[this.state.DisplayIndex].FileName.length - 3, this.state.AllData[this.state.DisplayIndex].FileName.length) === 'pdf' ? (
                <div className='dcc' style={{ width: '50%', flexDirection: 'column', cursor: 'pointer' }} onClick={() => this.DisplayPDFFile(this.state.SelectedImage)}>
                  <img style={{ width: 'auto', height: 'auto' }} src={PDF1} alt="logo" />
                </div>
              ) : (
                <ReactPanZoom
                  image={this.state.SelectedImage}
                  alt="Image alt text"
                />
              )} */}
            </>}
            {this.state.AllData.length === this.state.DisplayIndex + 1 ? (
              <div className="dcc" style={{ display: 'flex', textAlign: 'right', width: '20%'}}>{''}</div>
            ) : (
              <div className="dcc" style={{ display: 'flex', textAlign: 'right', width: '20%', fontSize: '40px', cursor: 'pointer '}} onClick={() => this.ClickNext()}>{'▶'}</div>
            )}
            {/* <div className="dcc" style={{ display: 'flex', textAlign: 'right', width: '20%', fontSize: '35px', cursor: 'pointer '}} onClick={() => this.ClickNext()}>{'⏭'}</div> */}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
    date: state.date,
    userID: state.userID,
  };
}

export default connect(mapStateToProps, null)(OpenSelectedImg);