import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import { Form, Alert } from 'react-bootstrap';
import {setConfiguration, getConfiguration } from '../Api'
import DatePicker from 'react-datepicker';
import Switch from "react-switch";
import moment from 'moment-timezone';
import Header from '../Component/Header';
import 'react-datepicker/dist/react-datepicker.css';

var timer
class ConfigurationTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      ConfigurationDate: moment().format('YYYY-MM-DD'),
      ConfigurationOpeningTime: moment("19:00", "HH:mm").format("HH:mm") ,
      ConfirmModal: false,
      ConfirmPassword: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      OriChecked: false,
      SelectedExample: this.props.currentLanguage,
      total: 0,
    }
  }

  async componentDidMount() {
    this.getConfi();
  }

  async getConfi() {
    const ResultConfiguration = await getConfiguration(this.props.username, this.props.token, moment(this.props.date).format('YYYY-MM-DD'));
    this.setState({ 
      checked: (ResultConfiguration.Active === 1) ? true : false, 
      OriChecked: (ResultConfiguration.Active === 1) ? true : false, 
      ConfigurationOpeningTime: moment(`${ResultConfiguration.CloseTime}`, "HH:mm").format("HH:mm"),
      ConfigurationDate: moment(ResultConfiguration.Date).format("YYYY-MM-DD"),
      total: ResultConfiguration.sumOrder,
    })
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  async ConfirmSubmitConfiguration(){
    const ConfigurationData = {
      Date: this.state.ConfigurationDate,
      Active: (this.state.checked) ? 1 : 0,
      Time: this.state.ConfigurationOpeningTime,
    }
    const r = (window.confirm(`确定要更新日期吗?`));
    if (r === true) {
      const resultconfigurationT= await setConfiguration(this.props.username, ConfigurationData, this.props.token);
      if (resultconfigurationT.message === 'Success Updated') {
        this.setState({ConfirmModal: false})
        this.openNotification('SuccessfulUpdate', 'success')
      }
      if (resultconfigurationT.error === 'Existed Date!!') {
        this.setState({ConfirmModal: false})
        this.openNotification('Existed Date', 'error')
      }
    } else {
      return null;
    }
  }

  setLanguage() {
    if(this.state.SelectedExample === 'cn') {
      this.setState({ SelectedExample: 'en' })
      this.props.setLanguage('en')
    } else {
      this.setState({ SelectedExample: 'cn' })
      this.props.setLanguage('cn')
    }
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 5000);
  };

  render() {
    return (
      <div fluid style={{ minHeight: '100vh', backgroundImage: 'linear-gradient(#D0F0F5, #E7EDFF, #E3D5FF)'}}>
        <Header/>
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        {this.props.role === 'Admin' && 
        <div className='dcc' style={{flexDirection: 'column', paddingTop: '5%', width: '100%'}}>
          <div className='dcc ChangePasswordContainer' style={{ backgroundColor: 'white'}}>
            <div style={{ fontWeight: 'bold', fontSize: '30px', marginBottom: 30, fontFamily: 'cursive' }}>{language[this.props.currentLanguage].SettingDate}</div>
            <div className='dcc ChangePAsswordTextView' style={{ fontFamily: 'cursive' }}>
              <div className='ChangeConfiguration'>这期单总数: </div>
              <div className='ChangeConfigurationInput'>
                {this.state.total}
              </div>
            </div>
            <div className='dcc ChangePAsswordTextView' style={{ fontFamily: 'cursive' }}>
              <div className='ChangeConfiguration'>{language[this.props.currentLanguage].ActiveDate}: </div>
              <div className='dcc ChangeConfigurationInput'>
                {/* <Form.Control min={new Date().toISOString().split("T")[0]} value={this.state.ConfigurationDate} onChange={(e) => this.setState({ ConfigurationDate: e.target.value})} type="date" style={{ marginRight: 10, width: 180 }}/> */}
                <div style={{ marginRight: 10, width: 180  }}>
                  <DatePicker
                    onChange = {(date) => this.setState({ ConfigurationDate: date})}
                    dateFormat = 'dd-MMM-yyyy'
                    placeholder="Date"
                    selected={new Date(this.state.ConfigurationDate)}
                    minDate={new Date()}
                    popperPlacement="top-right"
                    value={moment(this.state.ConfigurationDate).format('YYYY/MM/DD')}
                    className={"form-control StyleDatePicker"}
                    withPortal
                  />
                </div>
              </div>
            </div>
            <div className='dcc ChangePAsswordTextView' style={{ fontFamily: 'cursive' }}>
              <div className='ChangeConfiguration'>{language[this.props.currentLanguage].BetStatus}: </div>
              <div className='ChangeConfigurationInput'>
                <div style={{ width: '50%', display: 'flex', justifyContent: 'center' }}><Switch onChange={(e) => this.handleChange(e)} checked={this.state.checked} /></div>
              </div>
            </div>
            <div className='dcc ChangePAsswordTextView' style={{ fontFamily: 'cursive' }}>
              <div className='ChangeConfiguration'>{language[this.props.currentLanguage].BetDateCloseTime}: </div>
              <div className='dcc ChangeConfigurationInput'>
                <Form.Control value={this.state.ConfigurationOpeningTime} onChange={(e) => this.setState({ ConfigurationOpeningTime: e.target.value})} type="time" style={{ marginRight: 10, width: 180 }}/>
              </div>
            </div>
            {/* <Button style={{ minWidth: 150, padding: 5 }} onClick={() => this.ConfirmFunctions()} variant="primary">{language[this.props.currentLanguage].Confirm}</Button> */}
            <button class="pushable" style={{ minWidth: 150, padding: 2, marginTop: 10 }} onClick={() => this.ConfirmSubmitConfiguration()}>
              <span class="front" style={{ fontWeight: 'bold', fontFamily: 'cursive' }}>
                {language[this.props.currentLanguage].Update}
              </span>
            </button>
          </div>
        </div>
        }
        <div className={(this.props.role !== 'Admin') ? `dcc ConfiLanguageNoAdmin` : `dcc ConfiLanguage`}>
          <div className='dcc ChangePasswordContainer' style={{ backgroundColor: 'white'}}>
            <div style={{ fontWeight: 'bold', fontSize: '30px', marginBottom: 10, fontFamily: 'cursive' }}>{language[this.props.currentLanguage].LanguageSetting}</div>
            <div style={{ fontWeight: 'bold', fontSize: '30px', marginBottom: 10, fontFamily: 'cursive' }}>{'language Settings'}</div>
            {/* <div className='stylesss'>
              <span>I</span>M<span>POSSIBLE</span>
            </div> */}
            {/* <div className='stylesss2'>
              Get up and shine
            </div> */}
            <button class="pushable" style={{ minWidth: 150, padding: 2, marginTop: 10 }} onClick={() => this.setLanguage()}>
              <span class="front" style={{ fontWeight: 'bold', fontFamily: 'cursive' }}>
                {language[this.props.currentLanguage][this.state.SelectedExample]}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
    date: state.date,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg })
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationTime);
