import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { getBase64 } from '../Component/getBase64';
import { language } from '../language';
import { Form, Modal, Alert } from 'react-bootstrap';
import loading from '../image/loadings.gif';
import PDF1 from '../image/PDF2.png';
import { getAgentList, getAllPlayerListF, multiUpload } from '../Api'
import moment from 'moment-timezone';
import Header from '../Component/Header';

var timer

class HomepageUSers extends React.Component {

  fileObj = [];
  fileArray = [];
  newfileArray = [];
  Base64 = []

  constructor(props) {
    super(props);
    this.state = {
      UploadModal: false,
      UploadLoading: false,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      Base64file: [],
      file: [],
      SelectedUserName: '',
      userList: [],
      userListPlayer: [],
      AgentListDisable: false,
      PlayerListDisable: false,
      ConvertPDF: false,
    }
    this.handleClose = this.handleClose.bind(this);
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
  }

  async componentDidMount() {
    if(this.props.role === 'Player') {
      this.setState({ SelectedUserName: this.props.username })
    } else if (this.props.role === 'Da') {
      this.AgentList('');
    } else if (this.props.role === 'Agent') {
      this.getUserLists('');
    }
  }

  async AgentList(UserID) {
    this.setState({ AgentListDisable: true })
    const userList = await getAgentList(this.props.username, this.props.token, UserID, moment(this.props.date).format('YYYY-MM-DD'))
    if (userList) {
      this.setState({ userList, AgentListDisable: false })
    }
  }

  async getUserLists(UserID) {
    this.setState({ PlayerListDisable: true })
    const allPlayerList = await getAllPlayerListF(this.props.username, this.props.token, UserID, moment(this.props.date).format('YYYY-MM-DD'))
    if (allPlayerList) {
      this.setState({ userListPlayer: allPlayerList, PlayerListDisable: false});
    }
  }

  async UploadImages() {
    if (this.state.SelectedUserName === '') {
      this.openNotification(`请选择上传用户`)
      this.setState({ notificationType: 'error' })
    } else {
      this.setState({ 
        UploadModal: true
      })
    }
  }

  uploadMultipleFiles(e) {
    // const BeforeFileArray = this.newfileArray || []
    // const BeforeFilterfileArray = this.fileArray || []

    // BeforeFileArray.forEach((items) => {
    //   this.newfileArray.push(items)
    // })
    // BeforeFilterfileArray.forEach((items) => {
    //   this.fileArray.push(items)
    // })

    this.fileArray = []
    this.fileObj = []
    this.Base64 = []
    if (e.target.files.length > 0) {
      this.fileObj.push(e.target.files)
      for (let i = 0; i < this.fileObj[0].length; i++) {
        this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
        getBase64(this.fileObj[0][i]).then(
          Base64data => this.Base64.push(Base64data)
        )
      }
      this.setState({ file: this.fileArray, Base64file: this.Base64 })
    }
  }

  async uploadFiles(e) {
    this.setState({ UploadLoading: true })
    e.preventDefault()
    if (this.state.file.length === 0) {
      alert('No Selected files')
      this.setState({ UploadLoading: false })
    } else {
      this.setState({ UploadLoading: true })
      const file = []
      for (let i = 0; i < this.state.Base64file.length; i += 1) {
        if (this.state.Base64file[i].startsWith('data:image/jpeg;base64,')) {
          file.push({
            filetype: 'jpeg',
            base64: this.state.Base64file[i].substring(23, this.state.Base64file[i].length)
          })
        } else if (this.state.Base64file[i].startsWith('data:image/png;base64,')) {
          file.push({
            filetype: 'png',
            base64: this.state.Base64file[i].substring(22, this.state.Base64file[i].length)
          })
        } else if (this.state.Base64file[i].startsWith('data:application/pdf;base64,')) {
          file.push({
            filetype: 'pdf',
            base64: this.state.Base64file[i].substring(28, this.state.Base64file[i].length)
          })
        }
      }
      let multiUploadResult = ''
      if(this.props.role === 'Player') {
        multiUploadResult = await multiUpload(this.props.username, this.props.token, file, null, this.state.ConvertPDF)
      } else {
        multiUploadResult = await multiUpload(this.props.username, this.props.token, file, this.state.SelectedUserName, this.state.ConvertPDF)
      }
      if (multiUploadResult.message === 'Added Successful') {
        this.setState({ UploadLoading: false })
        this.handleClose();
        this.setState({ notificationType: 'success'})
        this.openNotification(`添加成功`)
      } else if (multiUploadResult.error) {
        this.openNotification(`${multiUploadResult.error}`)
        this.setState({ notificationType: 'error', UploadLoading: false })
      } else {
        this.setState({ UploadLoading: false })
      }
    }
  }

  async DisplayPDFFile(DataDetials) {
    window.open(DataDetials, '_blank', 'noopener,noreferrer');
  }

  handleClose() {
    this.setState({
      UploadModal: false,
      Base64file: [],
      file: [],
    })
    this.fileArray = []
    this.newfileArray = []
    this.fileObj = []
    this.Base64 = []
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 3000);
  };

  DeleteImage(url, index, type, Array) {
    console.log('index', index)
    console.log('url', url)
    // let newfileArray = this.newfileArray
    let fileArray = this.fileArray
    console.log('fileArray', fileArray)
    if(type === 'Upload') {
      const afterFilter = fileArray.filter(item => item.Img !== url.Img);
      this.fileArray = []
      this.Base64 = []
      afterFilter.forEach((items) => {
        this.fileArray.push(items)
        this.Base64.push(items.Base64)
      })
      this.setState({ file: this.fileArray })
    } else {
      if(Array === 'fileArray') {
        const afterFilter = fileArray.filter(item => item.Img !== url.Img);
        this.fileArray = []
        afterFilter.forEach((items) => {
          this.fileArray.push(items)
        })
        this.setState({ file: this.fileArray })
      } 
      // else {
      //   const afterFilter = newfileArray.filter(item => item.Img !== url.Img)
      //   this.newfileArray = []
      //   this.Base64 = []
      //   afterFilter.forEach((items) => {
      //     this.newfileArray.push(items)
      //     this.Base64.push(items.Base64)
      //   })
      //   this.setState({ file: this.newfileArray })
      // }
    }
  }

  render() {
    return (
      <div style={{ fontFamily: 'cursive', backgroundImage: 'linear-gradient(#D0F0F5, #E7EDFF, #F0E8FF)', minHeight: '100vh' }}>
        <Header/>
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <Modal centered show={this.state.UploadModal} size="lg" onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {language[this.props.currentLanguage].Upload}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
              <div style={{ width: 100 }}>{language[this.props.currentLanguage].Username}</div>
              <Form.Control style={{ width: 150}} value={this.state.SelectedUserName} disabled={true} />
            </div>
            {this.state.UploadLoading === false && 
            <div style={{ marginTop: 5, marginBottom: 10}}>
              <form>
                <div className="dcc form-group multi-preview" style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 10 }}>
                  {(this.fileArray || []).map((url, ind) => (
                    <div key={ind}>
                      {Object.values(this.fileObj[0])[ind].type.includes('image') ?
                        <>
                          <img style={{ width: 200, height: 200, padding: 1, cursor: 'pointer' }} src={url} alt="images" onClick={() => this.DisplayPDFFile(url, 'review')}/>
                          <div className='dcc' onClick={() => this.DeleteImage(url, ind, 'Upload', 'fileArray')} style={{ cursor: 'pointer', padding: 3, color: 'red', width: '100%'  }}>{language[this.props.currentLanguage].Delete}</div>
                        </>
                        : 
                        <div className='dcc' style={{ width: 200, height: 200, flexDirection: 'column', cursor: 'pointer' }} onClick={() => this.DisplayPDFFile(url, 'review')}>
                          <img style={{ width: 80, height: 80 }} src={PDF1} alt="logo" /><div>{`PDF File${ind+1}`}</div>
                        </div>}
                    </div>
                  ))}
                </div>
                <div className="form-group">
                  <input type="file" accept=".pdf,.jpg,.png,.jpeg" className="form-control" onChange={this.uploadMultipleFiles} multiple />
                </div>
                <div class="wrap" className='UserListButton' style={{  marginTop: 10 }}>
                  <button class="buttonAdd" onClick={this.uploadFiles}>{language[this.props.currentLanguage].UploadFiles}</button>
                </div>
              </form >
            </div>
            }
            {this.state.UploadLoading === true && 
            <>
              <div className='dcc'><img src={loading} alt="Logo" className="dcc loadingimages"/></div>
            </>}
            <div style={{ marginRight: 5 }}>
              <button class="buttonDelete" onClick={() => this.handleClose()}>{language[this.props.currentLanguage].Close}</button>
            </div>
          </Modal.Body>
        </Modal>
        <div className='dcc' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 'calc(90vh - 56px)'}}>
          {this.props.role !== 'Player' && 
            <div className='dcc HomePageUserListFlex' style={{ padding: 10 }}>
              {this.props.role === 'Da' && 
                <div className='dcc HomePageUserListfirst' style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ fontWeight: 'bold', fontSize: '28px' }}>{language[this.props.currentLanguage].Agent} :</div>
                  <Form.Select disabled={this.state.AgentListDisable} style={{ width: 200, height: 60, fontSize: '20px' }} onChange={(e) => {
                    this.setState({ filterAgent: e.target.value, filterUser: ''}, () => {
                      if (this.state.filterAgent !== '') {
                        this.getUserLists(this.state.filterAgent);
                      } else {
                        this.setState({ SelectedUserName: '',  userListPlayer: []})
                      }
                    })
                  }}>
                    <option value=''></option>
                    {this.state.userList.map((item, index) => 
                      <option key={index} value={item.ID}>{item.Name}{(item.TotalOrder !== 0) ? ` (${item.TotalOrder} 单)` : ''}</option>
                    )}
                  </Form.Select>
                </div>
              }
              <div className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ fontWeight: 'bold', fontSize: 30 }}>{language[this.props.currentLanguage].User} </div>
                <Form.Select disabled={this.state.PlayerListDisable} style={{ width: 300, height: 70, fontSize: 30 }} onChange={(e) => {
                  let GetUsernames = ''
                  if (e.target.value !== '') {
                    let GetUsername = this.state.userListPlayer.filter((value) => value.ID.toString() === e.target.value);
                    GetUsernames = GetUsername[0].Name
                  } else {
                    GetUsernames = ''
                  }
                  this.setState({ SelectedUserName: GetUsernames, filterUser: e.target.value})
                }}>
                  <option value=''></option>
                  {this.state.userListPlayer.map((item, index) =>
                    <option key={index} value={item.ID}>{item.Name}</option>
                  )}
                </Form.Select>
              </div>
            </div>
          }
          {this.props.AbleConvertPDF !== '0' && <div className='dcc' style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <div style={{ fontWeight: 'bold', fontSize: 30, marginRight: 10 }}>{'转换PDF > PNG'}</div>
            <Form.Check 
              type="switch"
              id="custom-switch"
              label=""
              className="biggerSwitch"
              checked={this.state.ConvertPDF}
              onChange={() => this.setState({ ConvertPDF: !this.state.ConvertPDF})}
              style={{  }}
            />
          </div>}
          <div className='dcc HomePageUserButton'
            style={{ borderRadius: 5, height: '35vh', marginTop: (this.props.role === 'Player') ? 50 : 0, marginBottom: 10,  backgroundColor: '#26C086', color: 'white', fontWeight: 'bold', fontSize: '50px' }}
            onClick={() => this.UploadImages()}>
            {language[this.props.currentLanguage].Upload}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
    date: state.date,
    userID: state.userID,
    AbleConvertPDF: state.AbleConvertPDF,
  };
}

export default connect(mapStateToProps, null)(HomepageUSers);
