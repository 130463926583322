import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import ShowPass from '../image/ShowPass.png';
import HidePass from '../image/HidePass.png';
import { language } from '../language';
import Header from '../Component/Header';
import { Container, Form, Alert } from 'react-bootstrap';
import { changePwdF } from '../Api'

var timer

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: this.props.username,
      NewPassword: '',
      ConfirmNewPassword: '',
      CurrentPassword: '',
      UserID: this.props.userID,
      token: this.props.token,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      DisplayPass: false,
      DisplayConPass: false,
      DisplayCurrentPass: true,
    }
  }

  async componentDidMount() {}

  async ConfirmFunctions() {
    const NewPassword = this.state.NewPassword
    const ConfirmPass = this.state.ConfirmNewPassword
    const CurrentPassword = this.state.CurrentPassword
    const UserID = this.state.UserID
    const Username = this.state.Username
    if(CurrentPassword === '') {
      this.openNotification(`EmptyCurrentPass`)
      this.setState({ notificationType: 'error' })
    } else if(NewPassword === '') {
      this.openNotification(`EmptyNewPass`)
      this.setState({ notificationType: 'error' })
    } else if(ConfirmPass === '') {
      this.openNotification(`EmptyNConPass`)
      this.setState({ notificationType: 'error' })
    } else if(NewPassword !== ConfirmPass) {
      this.openNotification(`PassNotMatch`)
      this.setState({ notificationType: 'error' })
    } else {
      const ChangePass = await changePwdF(Username, this.props.token, UserID, CurrentPassword, NewPassword)
      if(ChangePass.error === 'Wrong Current Password') {
        this.openNotification(`WorngCurrentPAss`)
        this.setState({ notificationType: 'error' })
      } 
      if(ChangePass.error === 'New Password Match with Current Password!') {
        this.openNotification(`SameNewandCurrentPass`)
        this.setState({ notificationType: 'error' })
      } 
      if(ChangePass.message === 'Password Changed Successful') {
        this.openNotification(`SuccessChangePass`)
        this.setState({ notificationType: 'success', ConfirmNewPassword: '', NewPassword: '', DisplayPass: false, DisplayConPass: false, CurrentPassword: '', DisplayCurrentPass: true })
      } 
    }
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: language[this.props.currentLanguage][message], notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 3000);
  };

  handleClose() {
    this.setState({ ConfirmNewPassword: '', NewPassword: '', DisplayPass: false, DisplayConPass: false, CurrentPassword: '', DisplayCurrentPass: true })
  }

  render() {
    return (
      <div fluid style={{ height: '100vh', backgroundImage: 'linear-gradient(#D0F0F5, #E7EDFF, #E3D5FF)'}}>
        <Header />
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div className='dcc' style={{flexDirection: 'column', paddingTop: '5%', width: '100%'}}>
          <div className='dcc ChangePasswordContainer' style={{ backgroundColor: 'white'}}>
            <div style={{ fontWeight: 'bold', fontSize: '30px', marginBottom: 30, fontFamily: 'cursive' }}>{language[this.props.currentLanguage].ChangePassword}</div>
            <div className='dcc' style={{ display: 'flex', marginBottom: 30, alignItems: 'center', width: '100%' }}>
              <div style={{ width: 100, fontSize: 18, fontWeight: 'bold', fontFamily: 'cursive' }}>{language[this.props.currentLanguage].Username}: </div>
              {/* <Form.Control value={this.state.Username} type="text" style={{ marginRight: 10, width: '20%', backgroundColor: 'white' }} readOnly/> */}
              <div style={{ fontWeight: 'bold', fontFamily: 'cursive' }}>{this.state.Username}</div>
            </div>
            {/* <div className='dcc ChangePAsswordTextView'>
              <div className='ChangePassName'>{language[this.props.currentLanguage].CurrentPassword}</div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <Form.Control 
                  value={this.state.CurrentPassword}
                  type='text' style={{ marginRight: 10, width: '100%', backgroundColor: 'white' }}
                  onChange={(e) => this.setState({ CurrentPassword: e.target.value })}
                />
                <div style={{ width: '5%'}}/>
              </div>
            </div> */}
            <div className='dcc ChangePAsswordTextView'>
              <div className='ChangePassName' style={{ fontFamily: 'cursive' }}>{language[this.props.currentLanguage].CurrentPassword}</div>
              <div className='ChangePassInput'>
                <Form.Control 
                  value={this.state.CurrentPassword}
                  type={(this.state.DisplayCurrentPass === true) ? 'text' : 'password'} style={{ marginRight: 10, width: '100%', backgroundColor: 'white', fontFamily: 'cursive' }}
                  onChange={(e) => this.setState({ CurrentPassword: e.target.value })}
                />
                <div style={{ width: '5%', cursor: 'pointer' }} onClick={() => this.setState({ DisplayCurrentPass: !this.state.DisplayCurrentPass })}>
                  {this.state.DisplayCurrentPass === true ? (
                    <img src={ShowPass} alt="Logo" className='PasswordEye' />
                  ) : (
                    <img src={HidePass} alt="Logo" className='PasswordEye' />
                  )}
                </div>
              </div>
            </div>
            <div className='dcc ChangePAsswordTextView' style={{ fontFamily: 'cursive'}}>
              <div className='ChangePassName'>{language[this.props.currentLanguage].NewPass}</div>
              <div className='ChangePassInput'>
                <Form.Control 
                  value={this.state.NewPassword}
                  type={(this.state.DisplayPass === true) ? 'text' : 'password'} style={{ marginRight: 10, width: '100%', backgroundColor: 'white' }}
                  onChange={(e) => this.setState({ NewPassword: e.target.value })}
                />
                <div style={{ width: '5%', cursor: 'pointer' }} onClick={() => this.setState({ DisplayPass: !this.state.DisplayPass })}>
                  {this.state.DisplayPass === true ? (
                    <img src={ShowPass} alt="Logo" className='PasswordEye' />
                  ) : (
                    <img src={HidePass} alt="Logo" className='PasswordEye' />
                  )}
                </div>
              </div>
            </div>
            <div className='dcc ChangePAsswordTextView' style={{ fontFamily: 'cursive'}}>
              <div className='ChangePassName'>{language[this.props.currentLanguage].ConfirmNewPass}</div>
              <div className='ChangePassInput'>
                <Form.Control 
                  value={this.state.ConfirmNewPassword}
                  type={(this.state.DisplayConPass === true) ? 'text' : 'password'} style={{ marginRight: 10, width: '100%', backgroundColor: 'white' }}
                  onChange={(e) => this.setState({ ConfirmNewPassword: e.target.value })}
                />
                <div style={{ width: '5%', cursor: 'pointer' }} onClick={() => this.setState({ DisplayConPass: !this.state.DisplayConPass })}>
                  {this.state.DisplayConPass === true ? (
                    <img src={ShowPass} alt="Logo" className='PasswordEye' />
                  ) : (
                    <img src={HidePass} alt="Logo" className='PasswordEye' />
                  )}
                </div>
              </div>
            </div>
            {/* <Button style={{ minWidth: 150, padding: 5 }} onClick={() => this.ConfirmFunctions()} variant="primary">{language[this.props.currentLanguage].Confirm}</Button> */}
            <button class="pushable" style={{ minWidth: 150, padding: 2 }} onClick={() => this.ConfirmFunctions()}>
              <span class="front" style={{ fontWeight: 'bold', fontFamily: 'cursive' }}>
                {language[this.props.currentLanguage].Confirm}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
    date: state.date,
    userID: state.userID,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
