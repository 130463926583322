import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
// import Slider from '../Component/Slider';
import io from 'socket.io-client';
import { Form, Table } from 'react-bootstrap';
import { language } from '../language';
import { getWorkData, readby, adminZhuYeFNC, socketbase, getBase64API } from '../Api';
import moment from 'moment-timezone';
import gridview from '../image/gridview.png';
import Header from '../Component/Header';
import listview from '../image/listview.png';

class Work extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      userObj: [],
      pdfData: [],
      gridView: false,
      DataQuantity: 0,
      loading: false,
      initial: false,
      SummaryDownline: []
      // SelectedImageModal: false,
      // SelectedImage: '',
    }
    // this.handleCloseImage = this.handleCloseImage.bind(this);
    this.DownloadPDFFile = this.DownloadPDFFile.bind(this);
    this.socket = io(socketbase);
  }

  async componentDidMount() {
    this.setState({ SummaryDownline: JSON.parse(this.props.SummaryDownline) || []})
    this.socket.on('connect', () => {
      this.socket.emit('join', 'ResultListener');
      this.socket.on('ResultListener', data => {
        if (data) {
          if(data.message === 'Added Successful') {
            let PassRefreshAgent = {
              UplineID: '',
              uplineName: '',
              AddData: '',
            }
            PassRefreshAgent.UplineID = data.uplineID
            PassRefreshAgent.uplineName = data.uplineName
            PassRefreshAgent.AddData = data.AddData
            // this.getPDFDataF(PassRefreshAgent);
            this.addDataWhenRefresh(PassRefreshAgent);
          }
        }
      });
    });
    if (this.props.role === 'Admin') {
      const UserListSelected = sessionStorage.getItem('UserSelectedList') || ''
      if (UserListSelected !== '') {
        this.adminZhuYeFNC(JSON.parse(UserListSelected));
      } else {
        this.adminZhuYeFNC('');
      }
    } else {
      this.getPDFDataF('');
    }
  }

  addDataWhenRefresh(RefreshData) {
    let SelectedUser = []
    let RunApi = false
    if (this.props.role === 'Admin') {
      SelectedUser = this.state.userList.filter((item) => item.selected === true)
    } else {
      for (var a=0; a< this.state.SummaryDownline.length; a++) {
        SelectedUser.push(
          { ID: this.state.SummaryDownline[a] }
        )
      }
    }
    if (RefreshData !== '') {
      for (var i=0; i<SelectedUser.length; i++) {
        if ((SelectedUser[i].ID).toString() === RefreshData.UplineID.toString()) {
          RunApi = true
        }
      }
      if (this.props.role === 'Admin'){
        this.adminZhuYeFNC(this.state.userList);
      }
    }
    if (RunApi === true) {
      let tempData = JSON.parse(JSON.stringify(this.state.pdfData))
      for (var t=0; t < RefreshData.AddData.length; t++) {
        tempData.push( RefreshData.AddData[t])
        // this.setState(({
        //   pdfData: [
        //     ...this.state.pdfData,
        //     RefreshData.AddData[t],
        //   ],
        // }));
      }
      this.setState({ pdfData: tempData, DataQuantity: this.state.DataQuantity + RefreshData.AddData.length})
    }
  }

  async adminZhuYeFNC(Selected) {
    let userList = await adminZhuYeFNC(this.props.username, this.props.token, moment(this.props.date).format('YYYY-MM-DD'))
    const userObj = {}
    userList.forEach((item) => {
      userObj[item.ID] = item.Name
    })
    userList = userList.map((item) => ({
      ...item,
      selected: false,
    }))
    if (Selected !== '') {
      for (var i=0;i<Selected.length;i++) {
        userList[i].selected = Selected[i].selected
      }
    }
    this.setState({ userList, userObj });
    if (!this.state.initial) {
      this.getPDFDataF('');
      this.setState({ initial: true })
    }
  }

  componentWillUnmount() {
    this.socket && this.socket.disconnect();
  }

  async getPDFDataF(RefreshAgent) {
    let SelectedUser = ''
    if (this.props.role === 'Admin') {
      SelectedUser = this.state.userList.filter((item) => item.selected === true)
    } else {
      SelectedUser = { ID: this.props.userID, Name: this.props.username, TotalOrder: 0, selected: true }
    }
    this.setState({ loading: true })
    let pdfData = await getWorkData(this.props.username, this.props.token, moment(this.props.date).format('YYYY-MM-DD'), SelectedUser)
    if (pdfData.message === 'Retrieve Successful') {
      sessionStorage.setItem("UserSelectedList", JSON.stringify(this.state.userList));
      this.setState({ pdfData: pdfData.Data, DataQuantity: pdfData.Data.length || 0, loading: false});
    } else if(pdfData.message === 'No Data') {
      this.setState({ pdfData: [], DataQuantity: 0, loading: false });
    }
  }

  async DisplayPDFFile(DataDetials) {
    window.open(DataDetials.Url, '_blank', 'noopener,noreferrer');
  }

  async DownloadPDFFile(DataDetials) {
    const link = DataDetials.Url.replace('https', 'http');
    // const link = DataDetials.Url.replace('https://scan-prd.s3.ap-southeast-1.amazonaws.com', 'https://d1egw2l1nerbt8.cloudfront.net').replace('https://scan-prd.s3.amazonaws.com', 'https://d1egw2l1nerbt8.cloudfront.net')
    fetch(link)
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = link.replace(/^.*[\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
    // const readbyResult = await readby(this.props.username, this.props.token, DataDetials.ID)
    // if (readbyResult.message === 'Read Successful') {
    //   this.getPDFDataF('')
    //   this.adminZhuYeFNC(this.state.userList);
    // }
    // const base64 = await getBase64API(this.props.username, this.props.token, DataDetials.Url)
    // const blob = this.b64toBlob(base64.base64, 'png', 512);
    // let blobUrl = window.URL.createObjectURL(blob);
    // let a = document.createElement('a');
    // a.download = DataDetials.Url.replace(/^.*[\\/]/, '');
    // a.href = blobUrl;
    // document.body.appendChild(a);
    // a.click();
    // a.remove();

    const readbyResult = await readby(this.props.username, this.props.token, DataDetials.ID)
    if (readbyResult.message === 'Read Successful') {
      this.getPDFDataF('')
      this.adminZhuYeFNC(this.state.userList);
    }
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  changeSelected(index, value, mode) {
    if (mode === 'all') {
      let copyUserList = JSON.parse(JSON.stringify(this.state.userList))
      copyUserList = copyUserList.map((item) => ({
        ...item,
        selected: value,
      }))
      this.setState({ userList: copyUserList })
    } else {
      const copyUserList = JSON.parse(JSON.stringify(this.state.userList))
      copyUserList[index].selected = value
      this.setState({ userList: copyUserList })
    }
  }

  changeSelectedpdfData(index, value) {
    const copyPDFData = JSON.parse(JSON.stringify(this.state.pdfData))
    copyPDFData[index].selected = value
    this.setState({pdfData: copyPDFData})
  }

  changeAllSelected(value) {
    this.setState({
      pdfData: this.state.pdfData.map((item) => ({
        ...item,
        selected: value
      })),
      allSelected: value
    })
  }

  async MultiDownloadPDFFile() {
    const multiFileID = []
    for (let i = 0; i < this.state.pdfData.length; i += 1) {
      if (this.state.pdfData[i].selected) {
        multiFileID.push(this.state.pdfData[i].ID)
        // const link = this.state.pdfData[i].Url.replace('https://scan-prd.s3.ap-southeast-1.amazonaws.com', 'https://d1egw2l1nerbt8.cloudfront.net').replace('https://scan-prd.s3.amazonaws.com', 'https://d1egw2l1nerbt8.cloudfront.net')
        const link = this.state.pdfData[i].Url.replace('https', 'http');
        fetch(link)
          .then(response => response.blob())
          .then(blob => {
            let blobUrl = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.download = link.replace(/^.*[\\/]/, '');
            a.href = blobUrl;
            document.body.appendChild(a);
            a.click();
            a.remove();
          })
        // const base64 = await getBase64API(this.props.username, this.props.token, this.state.pdfData[i].Url)
        // const blob = this.b64toBlob(base64.base64, 'png', 512);
        // let blobUrl = window.URL.createObjectURL(blob);
        // let a = document.createElement('a');
        // a.download = this.state.pdfData[i].Url.replace(/^.*[\\/]/, '');
        // a.href = blobUrl;
        // document.body.appendChild(a);
        // a.click();
        // a.remove();
      }
    }
    const readbyResult = await readby(this.props.username, this.props.token, null, multiFileID)
    if (readbyResult.message === 'Read Successful') {
      this.getPDFDataF('')
    }
  }

  // handleCloseImage() {
  //   this.setState({
  //     SelectedImageModal: false,
  //     SelectedImage: '',
  //   })
  // }

  render() {
    return (
      <div fluid style={{ fontFamily: 'cursive', width: '100%' }}>
        <Header/>
        {/* {(this.state.SelectedImageModal === true) && 
        <OpenSelectedImg SelectedImageModal={this.state.SelectedImageModal} handleCloseImage={this.handleCloseImage} SelectedImage={this.state.SelectedImage} DownloadPDFFile={this.DownloadPDFFile}/>
        } */}
        {/* <Container fluid style={{ height: '95vh', backgroundImage: 'linear-gradient(#D0F0F5, #E7EDFF, #D0F5F3)'}}> */}
        <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
            <h2 className='TitleSize'>{language[this.props.currentLanguage].Home}</h2>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className='TotalFontSize' style={{ fontWeight: 'bold', paddingRight: 10 }}>{`${language[this.props.currentLanguage].Total}  : ${this.state.DataQuantity}`}</div>
            {/* <Button style={{ minWidth: 80, marginRight: 10 }} onClick={() => this.MultiDownloadPDFFile()} variant="primary">{language[this.props.currentLanguage].MultiDownload}</Button> */}
            <div class="wrap" style={{ marginRight: 5 }}>
              <button class="button2" onClick={() => this.MultiDownloadPDFFile()}>{language[this.props.currentLanguage].MultiDownload}</button>
            </div>
            <img src={gridview} alt={'gridview'} style={{ borderRadius: 10, cursor: 'pointer', padding: 10, width: 40, height: 40, backgroundColor: !this.state.gridView ? '#fff': '#0d6efd'}} onClick={() => this.setState({ gridView: true })}/>
            <img src={listview} alt={'listview'} style={{ borderRadius: 10, cursor: 'pointer', padding: 10, width: 40, height: 40, backgroundColor: this.state.gridView ? '#fff': '#0d6efd'}} onClick={() => this.setState({ gridView: false })}/>
          </div>
        </div>
        {/* <Slider /> */}
        <div className='TableDisplay' style={{ width: '100%'}}>
          {this.props.role === 'Admin' && <div className='WorkLeftTable'>
            <Table striped bordered hover responsive style={{ backgroundColor: 'white' }}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{language[this.props.currentLanguage].Name}</th>
                  <th>{language[this.props.currentLanguage].Ticket}</th>
                  <th>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ border: '1px solid #ddd', display: 'flex', padding: 5, marginRight: 10}}>
                        <Form.Check type="checkbox" checked={this.state.createUserActive} onChange={(e) => this.changeSelected('', e.target.checked, 'all')}/>
                        {language[this.props.currentLanguage].SelecteAll}
                      </div>
                      {/* <Button style={{ minWidth: 80 }} onClick={() => this.getPDFDataF()} variant="primary">{language[this.props.currentLanguage].Search}</Button> */}
                      <div class="wrap" style={{ marginRight: 5 }}>
                        <button class="button2" onClick={() => this.getPDFDataF('')}>{language[this.props.currentLanguage].Confirm}</button>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              {this.state.userList.length > 0 ?
                <tbody className='ListFontSize'>
                  {this.state.userList.map((item, index) => <tr key={index}>
                    <td>{item.ID}</td>
                    <td>{item.Name}</td>
                    <td>{item.TotalOrder}</td>
                    <td><Form.Check type="checkbox" checked={item.selected} onChange={(e) => this.changeSelected(index, e.target.checked)}/></td>
                  </tr>)}
                </tbody>
                :
                <tbody>
                  <tr style={{ backgroundColor: 'lightgray'}}>
                    <td colSpan="9" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{'没数据'}</td>
                  </tr>
                </tbody>}
            </Table>
          </div>}
          <div style={{ width: '100%'}}>
            {this.state.gridView
              ? <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap'}}>
                {this.state.pdfData.length > 0 ?
                  this.state.pdfData.map((item, index) => 
                    <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #000', padding: 10, alignItems: 'center', margin: 5}}>
                      <img src={item.Url} alt={item.FileName} style={{ maxWidth: 200, maxHeight: 200}} onClick={() => this.DisplayPDFFile(item)}/>
                      <div>{moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                      <div>{item.Username}</div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {/* <Button style={{ minWidth: 80 }} onClick={() => this.DownloadPDFFile(item)} variant="primary">{language[this.props.currentLanguage].Download}</Button> */}
                        <div class="wrap" style={{ marginRight: 5 }}>
                          <button class="button2"  onClick={() => this.DownloadPDFFile(item)}>{language[this.props.currentLanguage].Download}</button>
                        </div>
                        <Form.Check style={{ marginLeft: 10 }} type="checkbox" checked={item.selected} onChange={(e) => this.changeSelectedpdfData(index, e.target.checked)}/>
                      </div>
                    </div>
                  )
                  :
                  <div style={{ backgroundColor: 'lightgray', fontSize: 20}}>{language[this.props.currentLanguage].NoData}</div>}
              </div> 
              : <Table striped bordered hover responsive style={{ backgroundColor: 'white', borderRadius: 5 }}>
                <thead>
                  <tr>
                    <th>No</th>
                    <th style={{ minWidth: 180 }}>{language[this.props.currentLanguage].Name}</th>
                    <th style={{ minWidth: 180 }}>{language[this.props.currentLanguage].UploadTime}</th>
                    <th style={{ minWidth: 120 }}>{language[this.props.currentLanguage].Date}</th>
                    <th style={{ minWidth: 60 }}>{language[this.props.currentLanguage].User}</th>
                    <div style={{ display: 'flex', minWidth: 100 }}>
                      {language[this.props.currentLanguage].Action}
                      <Form.Check style={{ marginLeft: 10 }} type="checkbox" checked={this.state.allSelected} onChange={(e) => this.changeAllSelected(e.target.checked)}/>
                    </div>
                  </tr>
                </thead>
                {this.state.loading === true ? (
                  <>
                    <tbody>
                      <tr style={{ backgroundColor: 'lightgray'}}>
                        <td colSpan="9" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].loading}</td>
                      </tr>
                    </tbody>
                  </>
                ) : (
                  <>
                    {this.state.pdfData.length > 0 ?
                      <tbody className='ListFontSize'>
                        {this.state.pdfData.map((item, index) => <tr key={index}>
                          <td>{index + 1}</td>
                          {/* <td style={{ cursor: 'pointer' }} className="buttonshadow" onClick={() => this.setState({ SelectedImageModal: true, SelectedImage: item })}>{item.FileName}</td> */}
                          <td>{item.FileName}</td>
                          <td>{moment(item.DateTime).format('hh:mm:ss a YYYY-MM-DD')}</td>
                          <td>{moment(item.Date).format('YYYY-MM-DD')}</td>
                          <td>{item.Username}</td>
                          <td style={{ display: 'flex', alignItems:'center' }}>
                            {/* <Button style={{ minWidth: 80, marginRight: 10 }} onClick={() => this.DisplayPDFFile(item)} variant="primary">打开</Button> */}
                            {/* <Button style={{ minWidth: 80 }} onClick={() => this.DownloadPDFFile(item)} variant="primary">{language[this.props.currentLanguage].Download}</Button> */}
                            <div class="wrap" style={{ marginRight: 5 }}>
                              <button class="button2" onClick={() => this.DownloadPDFFile(item)}>{language[this.props.currentLanguage].Download}</button>
                            </div>
                            <Form.Check style={{ marginLeft: 10 }} type="checkbox" checked={item.selected} onChange={(e) => this.changeSelectedpdfData(index, e.target.checked)}/>
                          </td>
                        </tr>)}
                      </tbody>
                      :
                      <tbody>
                        <tr style={{ backgroundColor: 'lightgray'}}>
                          <td colSpan="9" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
                        </tr>
                      </tbody>}
                  </>
                )}
              </Table> }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
    role: state.role,
    date: state.date,
    userID: state.userID,
    SummaryDownline: state.SummaryDownline,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Work);
