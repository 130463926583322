import jsPDF from 'jspdf';
import moment from 'moment-timezone';

export function ExportPdf(ClaimsDetails, ExportUser) {
  var doc = new jsPDF('p', 'pt');

  for(var i=0; i<ClaimsDetails.length;i++) {
    // const imgData = 'https://as2.ftcdn.net/jpg/00/42/98/87/500_F_42988762_JMNpHWOFWnbtCBZeYsRo5PmzD28rIquS.jpg'
    // let imageprint = ClaimsDetails[i].Img.replace('https://scan-prd.s3.ap-southeast-1.amazonaws.com', 'https://d1egw2l1nerbt8.cloudfront.net').replace('https://scan-prd.s3.amazonaws.com', 'https://d1egw2l1nerbt8.cloudfront.net')
    let imageprint = ClaimsDetails[i].Img.replace('https', 'http')
    // doc.setFontSize(40)
    // doc.text(50, 50, `${ClaimsDetails[i].Username}`)

    doc.addImage(imageprint, 'PNG', 50, 50, 500, 650)

    const Checklength = ClaimsDetails.length - 1
    if(Checklength !== i) {
      doc.addPage()
    }
    // doc.addPage()
  }
  doc.save(`Receipt-${moment(new Date()).format('DD-MM-YYYY HH:mm:ss')}/${ExportUser}.pdf`)
}
